import React from 'react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'throttle-debounce';
import { InputText } from './inputs';

import './styles/tableList.css';

function TableList(props) {
  const navigate = useNavigate()
  return (

    <div className='containerTableListarClientes'>
      <table >
        <thead style={{ top: props.stickyTop ? `${props.stickyTop}px` : 0 }}>
          <tr>
            <td colSpan={8} style={{ border: 'none' }}><h2>{props.title}</h2></td>
            {/* <td colSpan={2} style={{ border: 'none' }}>
              <InputText
                percento_width={100}
                span={props.search_span}
                onChange={debounce(1000, (text) => props.onSearch(text))} />
            </td> */}
          </tr>
          <tr>

            {
              props.columns.map((el, index) => {
                return (<th key={index}>{el}</th>)
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            props.dados.length === 0 && (
              <tr>
                <td colSpan={7}>Nenhum Registro Encontrado</td>
              </tr>
            )
          }
          {
            props.dados.map((el, index) => (
              <tr key={index}
                // onClick={() => {
                //   navigate(props.link + el[0])
                //   // props.onSelected(el[0])
                // }}
                >
                {
                  el.map((el, index) => {
                    if (index > 0)
                      return (<td key={index}>{el}</td>)
                  })
                }
              </tr>

            ))}
          {props.quant_page <= props.quant_dados &&
            <tr>
              <td colSpan={2} style={{ border: 'none' }}>
                <div className='containerPageOptionsListarClientes'>

                  <button
                    onClick={() => props.onSelectPage(props.page === 1 ? 1 : props.page - 1)} >
                    <IoChevronBack />
                  </button>
                  <button
                    className={props.page === 1 ? 'buttonSelectedPageListarClientes' : ''}
                    onClick={() => props.onSelectPage(props.page === 1 ? 1 : props.page - 1)} >
                    {props.page === 1 ? 1 : props.page - 1}
                  </button>

                  <button
                    className={props.page === 1 ? '' : 'buttonSelectedPageListarClientes'}
                    onClick={() => props.onSelectPage(props.page === 1 ? 2 : props.page)} >
                    {props.page === 1 ? 2 : props.page}
                  </button>
                  {((props.page === 1 && props.quant_dados > props.quant_page * 2) || (props.page > 1 && props.quant_dados > props.quant_page * props.page)) &&
                    <button
                      onClick={() => props.onSelectPage(props.page === 1 ? 3 : props.page + 1)} >
                      {props.page === 1 ? 3 : props.page + 1}
                    </button>
                  }
                  <button
                    onClick={() => props.onSelectPage(props.quant_dados > props.quant_page * props.page ? props.page + 1 : props.page)}>
                    <IoChevronForward />
                  </button>
                </div>
              </td>
            </tr>
          }
          {/* {
            !loadingPesquisa && hasMoreClientes && (
              <div onClick={() => listarClientes()} className='showMore-ListarClientes'>
                <h3>Mostrar mais clientes</h3>
                <IoChevronDown />
              </div>
            )
          } */}
        </tbody>
      </table>
    </div>
  );
}

export default TableList;