import React from 'react';
import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom';

import NovoRegistro from './pages/Registros/novoRegistro'
import EditarRegistro from './pages/Registros/editarRegistro'
import ListarRegistros from './pages/Registros/listarRegistros'
import Login from './pages/Login/login'
import Indicadores from './pages/Indicadores/indicadores'
import Navigation from './utils/components/navigation';

export default function Routes() {

  return (

    <BrowserRouter >
      <Navigation>
        <Switch>
          <Route path="/" exact element={<ListarRegistros />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/indicadores" exact element={<Indicadores />} />
          <Route path="/novoRegistro" exact element={<NovoRegistro />} />
          <Route path="/editarRegistro/:id" exact element={<EditarRegistro />} />
        </Switch>
      </Navigation>
    </BrowserRouter>
  )
}