import React from 'react';

import './styles/card.css';

function Card(props) {
  return (
    <div className="containerCard" id={props.id} 
    onClick={props.onClick}
    style={{
      display:props.display,
      width:props.percento_width?props.percento_width+'%':'70%',
      maxWidth:props.percento_width?props.percento_width+'%':'70%',
      ...props.style
      }}>
      {props.children}
    </div>
  );
}

export default Card;