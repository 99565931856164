/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import './styles/inputs.css'
import { IoChevronDown, IoCloseSharp } from 'react-icons/io5';
import CircularProgress from '@material-ui/core/CircularProgress';


export function InputText(props) {

  return (
    <div className="InputTextContainer" style={{ width: `${props.percento_width}%` }}>
      <p id="InputTextSpan">{props.span} {props.required && '*'}</p>
      <input
        ref={props.ref_}
        type={props.type}
        disabled={props.disabled}
        minLength={props.minLength}
        maxLength={props.maxLength}
        required={props.required}
        onChange={(text) => props.onChange && props.onChange(text.target.value)}
        value={props.value}
        onKeyDown={(key) => {
          //? IMPEDIR CURSOR DO INPUT DE MUDAR DE POSIÇÃO
          if (props.onPressEnter && key.which === 13) {
            key.preventDefault()
            props.onPressEnter()
          }
        }}
        id="InputTextInput" />
      <span></span>
    </div>
  );
}
export function InputNumber(props) {

  return (
    <div className="InputTextContainer"
      style={{ width: `${props.percento_width}%` }}>
      <p id="InputTextSpan">{props.span} {props.required && '*'}</p>
      <input ref={props.ref_} id="InputTextInput" type="text"
        maxLength={props.maxLength}
        disabled={props.disabled}
        style={props.style}
        value={props.value}
        onChange={(text) => props.onChange && props.onChange(text.target.value.replace(/\D/g, ''))}
        onKeyDown={(key) => {
          //? IMPEDIR CURSOR DO INPUT DE MUDAR DE POSIÇÃO
          if (props.onPressEnter && key.which === 13) {
            key.preventDefault()
            props.onPressEnter()
          }
        }}
      />
    </div>
  );
}
export function InputTextArea(props) {

  return (
    <div className="InputTextContainer" style={{ width: `${props.percento_width}%`, ...props.style }}>
      <p id="InputTextSpan">{props.span} {props.required && '*'}</p>
      <textarea
        autoComplete='off'
        resizeble="false"
        style={{ height: 'calc(100% - 26px)' }}
        disabled={props.disabled}
        minLength={props.minLength}
        maxLength={props.maxLength}
        value={props.value}
        required={props.required}
        onChange={(text) => props.onChange && props.onChange(text.target.value)}
        id="InputTextInput"></textarea>
      <span></span>
    </div>
  );
}
export function InputCpfCnpj(props) {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (props.value === '')
      setValue('')
  }, [props.value])

  function removerFormatacao(value) {
    if (props.onChange && value)
      setValue(value.replace(/(\.|\/|\-)/g, ""))
  }
  function formatacao(value) {
    if (props.onChange && value)
      if (value.length <= 11) {
        setValue(value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4"))
      } else {
        setValue(value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5"))
      }
  }

  return (
    <div className="InputTextContainer" style={{ width: `${props.percento_width}%` }}>
      <p id="InputTextSpan">{props.span ? props.span : "CPF ou CNPJ"} {props.required && '*'}</p>
      <input id="InputTextInput"
        value={value}
        required={props.required}
        onFocus={(text) => removerFormatacao(text.target.value)}
        onBlur={(text) => formatacao(text.target.value)}
        onChange={(text) => {
          setValue(text.target.value.replace(/\D/g, ''))
          if (props.onChange)
            props.onChange(text.target.value.replace(/(\.|\/|\-)/g, ""))
        }}
        type="text" disabled={props.disabled}
        maxLength={14}
      />
      <span></span>
    </div>
  );
}
export function InputCurrency(props) {

  const defaultMaskOptions = {
    prefix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false
  }
  const currencyMask = createNumberMask({
    ...defaultMaskOptions
  })

  return (
    <div className="InputCurrencyContainer" tipo={props.sufix ? 'sufix' : 'prefix'} style={{ width: `${props.percento_width}%` }}>
      <p id="InputTextSpan">{props.span} {props.required && '*'}</p>
      <MaskedInput
        mask={currencyMask}
        required={props.required}
        render={(functionRef, pr) => (
          <>
            <input
              inputMode='decimal'
              autoComplete='off'
              {...pr}
              onChange={(text) => props.onChange && props.onChange(text.target.value.replaceAll(new RegExp('[.]', 'g'), '').replaceAll(',', '.'))}
              ref={ref => {
                functionRef(ref)
                if (props.refer_) {
                  props.refer_(ref);
                }
                if (props.ref_)
                  props.ref_.current = ref;
              }}
              // value={props.value}
              type={props.type}
              disabled={props.disabled}
              minLength={props.minLength}
              maxLength={props.maxLength}
              required={props.required}
              id="InputTextInput"
              onKeyDown={(key) => {
                //? IMPEDIR CURSOR DO INPUT DE MUDAR DE POSIÇÃO
                if (props.onPressEnter && key.which === 13) {
                  key.preventDefault()
                  props.onPressEnter()
                }
              }} />
            <span></span>
            {props.prefix && <span span_={props.span} id="prefix">{props.prefix}</span>}
            {props.sufix && <span span_={props.span} id="sufix">{props.sufix}</span>}
          </>
        )}
      />
      {/* <span></span> */}
      {props.prefix && <span span_={props.span} id="prefix">{props.prefix}</span>}
      {props.sufix && <span span_={props.span} id="sufix">{props.sufix}</span>}

    </div>
  );
}
export function SelectCreate(props) {
  const [value, setValue] = useState('')
  const [textValue, setTextValue] = useState('')
  const [focus, setFocus] = useState(0)
  const [inputSelecionado, setInputSelecionado] = useState(null)
  function handleOnCreate(e) {
    e.target.blur()
    setValue('')
    setTextValue('')
    props.onCreate()
  }
  useEffect(() => {
    if (props.value === null) {
      setTextValue('')
      setValue('')
    }
    if (props.updateText && props.updateText.trim() !== '') {
      setTextValue(props.updateText)
      setValue(props.updateText)
    }
  }, [props.updateText, props.value])

  return (
    <div className="InputTextContainer"
      onBlur={(r) => {
        if (r.relatedTarget && r.relatedTarget.className === "InputTextContainer")
          if (value.trim() === '' || (textValue.trim().length > 0 && props.data && props.data.length === 0) || (textValue.trim().length <= 0 && props.defaultData && props.defaultData.length > 0))
            return
        if (value !== textValue || (value.trim().length <= 0 && !inputSelecionado)) {
          setTextValue(value)
        }
      }}
      tabIndex="0"
      id="InputSelect"
      style={{ width: `calc(${props.percento_width}%)`, position: 'relative', ...props.styles }}>
      <p id="InputTextSpan">{props.span} {props.required && '*'}</p>
      <input ref={props.ref_}
        disabled={props.disabled}
        autoComplete='off'
        onFocus={(el) => {

          setInputSelecionado(true)
          el.target.select()
          if (props.eraseOnFocus) {
            setValue('')
            setTextValue('')
          }
          if (props.onFocus)
            props.onFocus()
        }}
        required={props.required}
        id="InputTextInput"
        className='InputTextInputSelectCreate'
        value={textValue}
        placeholder="Selecione..."
        onKeyDown={(key) => {
          //? IMPEDIR CURSOR DO INPUT DE MUDAR DE POSIÇÃO
          if (key.which === 38 || key.which === 40)
            key.preventDefault()
          //? VERIFICAR SE EXISTE DADOS
          if (props.loading || (((!props.data || props.data.length <= 0) && (!props.defaultData || props.defaultData.length <= 0)) && textValue.trim().length <= 0))
            return
          if ((props.data.length <= 0 || props.defaultData.length <= 0) && props.dontCreate)
            return
          //? AO APERTAR PRA CIMA
          if (key.which === 38) {
            if (textValue.trim().length > 0 && props.data && props.data.length > 0) {
              setFocus(focus === 0 ? props.data.length - 1 : focus - 1)
              if (document.querySelector('.SelectMenuContainer .focus'))
                document.querySelector('.SelectMenuContainer .focus').scrollIntoView({ behavior: 'smooth', block: "center" })
            } else if (props.defaultData && props.defaultData.length > 0) {
              setFocus(focus === 0 ? props.defaultData.length - 1 : focus - 1)
              if (document.querySelector('.SelectMenuContainer .focus'))
                document.querySelector('.SelectMenuContainer .focusDefault').scrollIntoView({ behavior: 'smooth', block: "center" })
            }
          } else
            //? AO APERTAR PRA BAIXO
            if (key.which === 40) {
              if (textValue.trim().length > 0 && props.data && props.data.length > 0) {
                setFocus(focus === props.data.length - 1 ? 0 : focus + 1)
                if (document.querySelector('.SelectMenuContainer .focus'))
                  document.querySelector('.SelectMenuContainer .focus').scrollIntoView({ behavior: 'smooth', block: "center" })
              } else if (props.defaultData && props.defaultData.length > 0) {
                setFocus(focus === props.defaultData.length - 1 ? 0 : focus + 1)
                if (document.querySelector('.SelectMenuContainer .focus'))
                  document.querySelector('.SelectMenuContainer .focusDefault').scrollIntoView({ behavior: 'smooth', block: "center" })
              }
            } else
              //? AO APERTAR ENTER
              if (key.which === 13) {
                if (textValue.trim().length > 0 && props.data.length > 0) {
                  setTextValue(`${props.data[focus].Nome}${props.onlyNome ? '' : ' - ' + props.data[focus].Descricao}`)
                  setValue(`${props.data[focus].Nome}${props.onlyNome ? '' : ' - ' + props.data[focus].Descricao}`)

                  setTimeout(() => {
                    props.onSelectOption(props.data[focus].Elemento)
                    key.target.blur()
                  }, 100)
                } else if (props.defaultData.length > 0) {
                  setTextValue(`${props.defaultData[focus].Nome}${props.onlyNome ? '' : ' - ' + props.defaultData[focus].Descricao}`)
                  setValue(`${props.defaultData[focus].Nome}${props.onlyNome ? '' : ' - ' + props.defaultData[focus].Descricao}`)

                  setTimeout(() => {
                    props.onSelectOption(props.defaultData[focus].Elemento)
                    key.target.blur()
                  }, 100)

                } else if (!props.loading && textValue.trim().length > 0 && value.trim() !== textValue.trim() && props.data && props.data.length == 0) {
                  //? MOSTRAR MODAL AO APERTAR ENTER NO CADASTRAR
                  handleOnCreate(key)
                }
              } else {
                //? AO MUDAR O VALOR DA PESQUISA IRÁ VOLTAR PARA PRIMEIRA OPÇÃO
                setFocus(0)
              }
        }}
        onChange={(text) => {
          props.setLoading(true)
          setTextValue(text.target.value)
          if (!props.ignoreLength)
            if (text.target.value.trim().length <= 0) {
              text.target.nextSibling.style.display = "none"
              props.setLoading(false)
              return
            }
          props.onChange(text.target.value)

        }} />
      <span></span>
      {
        textValue.trim().length > 0 ?
          <IoCloseSharp span_={props.span}
            style={{ cursor: 'pointer' }}
            className="InputSelectIcon"
            onClick={() => {
              if (!props.dontClear) {
                setTextValue('')
                setValue('')
              }
              if (props.onClear)
                props.onClear()
            }}
          />
          :
          <IoChevronDown span_={props.span} style={{ pointerEvents: 'none' }} className="InputSelectIcon" />
      }

      {
        textValue.trim().length > 0 && !props.loading && !props.disabled && props.data ? (
          <div className="SelectMenuContainer" style={{ display: props.data.length > 0 ? '' : 'none' }}>
            {
              props.data.map((el, index) => (
                <div id="SelectOptionContainer"
                  className={focus == index ? "focus" : ""}
                  onClick={() => {
                    setTextValue(`${el.Nome}${props.onlyNome ? '' : ' - ' + el.Descricao}`)
                    setValue(`${el.Nome}${props.onlyNome ? '' : ' - ' + el.Descricao}`)
                    setTimeout(() => {
                      props.onSelectOption(el.Elemento)
                    }, 100)
                  }} key={index} >
                  <div>
                    <p>{el.Nome}</p>
                    <p>{el.Descricao}</p>
                  </div>
                  <p>{el.TextoAdicional}</p>
                </div>
              ))
            }
          </div>
        ) : textValue.trim().length > 0 && props.loading &&
        <div className="SelectMenuLoadingContainer">
          <p>Carregando...</p>
        </div>
      }
      {
        !props.dontCreate && !props.loading && textValue.trim().length > 0 && value.trim() !== textValue.trim() && props.data && props.data.length == 0 &&
        <div className="SelectMenuCreateContainer"
          onClick={(e) => handleOnCreate(e)}>
          <p style={{ wordBreak: 'break-all' }}>Cadastrar <b>"{textValue}"</b></p>
        </div>
      }
      {
        textValue.trim().length <= 0 && !props.loading && props.defaultData && !props.disabled && props.defaultData.length > 0 && inputSelecionado &&
        <div className="SelectMenuContainer" style={{ display: props.defaultData.length > 0 ? '' : 'none' }}>
          {
            props.defaultData.map((el, index) => (
              <div id="SelectOptionContainer"
                className={focus == index ? "focusDefault" : ""}
                onClick={() => {
                  setInputSelecionado(false)
                  setTextValue(`${el.Nome}${props.onlyNome ? '' : ' - ' + el.Descricao}`)
                  setValue(`${el.Nome}${props.onlyNome ? '' : ' - ' + el.Descricao}`)
                  setTimeout(() => {
                    props.onSelectOption(el.Elemento)
                  }, 100)
                }} key={index} >
                <div>
                  <p>{el.Nome}</p>
                  <p>{el.Descricao}</p>
                </div>
                <p>{el.TextoAdicional}</p>
              </div>
            ))
          }
        </div>
      }
    </div>

  )
}

export function Select(props) {
  const [value, setValue] = useState('')
  const [textValue, setTextValue] = useState('')
  const [focus, setFocus] = useState(0)
  const [data, setData] = useState(['Entrada', 'Saída'])
  const [inputSelecionado, setInputSelecionado] = useState(null)
  useEffect(() => {
    if (props.value === null) {
      setTextValue('')
      setValue('')
    } else {
      setTextValue(props.value ? 'Entrada' : 'Saída')
      setValue(props.value ? 'Entrada' : 'Saída')
    }
  }, [props.value])
  return (
    <div className="InputTextContainer"
      onBlur={(r) => {
        if (r.relatedTarget && r.relatedTarget.className === "InputTextContainer")
          if (value.trim() === '' || (textValue.trim().length > 0 && props.data && props.data.length === 0))
            return
        if (value !== textValue || value.trim().length <= 0)
          setTextValue(value)
      }}
      tabIndex="0" id="InputSelect" style={{ width: `calc(${props.percento_width}% )`, position: 'relative' }}>
      <p id="InputTextSpan">{props.span} {props.required && '*'}</p>
      <input ref={props.ref_} required id="InputTextInput"
        value={textValue} placeholder="Selecione..."
        inputMode='none'
        disabled={props.disabled}
        onFocus={() => setInputSelecionado(true)}
        style={{ cursor: 'pointer', caretColor: 'transparent' }}
        onKeyDown={(key) => {
          //? IMPEDIR CURSOR DO INPUT DE MUDAR DE POSIÇÃO
          if (key.which === 38 || key.which === 40)
            key.preventDefault()
          //? VERIFICAR SE EXISTE DADOS
          if (((!data || data.length <= 0) && textValue.trim().length <= 0))
            return

          //? AO APERTAR PRA CIMA
          if (key.which === 38 && data && data.length > 0) {
            setFocus(focus === 0 ? data.length - 1 : focus - 1)
            document.querySelector('.SelectMenuContainer .focus').scrollIntoView({ behavior: 'smooth', block: "center" })
          } else
            //? AO APERTAR PRA BAIXO
            if (key.which === 40 && data && data.length > 0) {
              setFocus(focus == data.length - 1 ? 0 : focus + 1)
              document.querySelector('.SelectMenuContainer .focus').scrollIntoView({ behavior: 'smooth', block: "center" })
            } else
              //? AO APERTAR ENTER
              if (key.which === 13) {
                if (data.length > 0) {
                  setTextValue(`${data[focus]}`)
                  setValue(`${data[focus]}`)

                  setTimeout(() => {
                    props.onSelectOption(data[focus] === 'Entrada' ? true : false)
                    setInputSelecionado(false)
                    key.target.blur()
                  }, 100)
                }
              } else {
                //? AO MUDAR O VALOR DA PESQUISA IRÁ VOLTAR PARA PRIMEIRA OPÇÃO
                setFocus(0)
              }
        }}
        onChange={(text) => {
          // setTextValue(text.target.value)
          if (text.target.value.trim().length <= 0) {
            text.target.nextSibling.style.display = "none"
            return
          }
        }} />
      {!inputSelecionado &&
        <span></span>}
      <IoChevronDown style={{ pointerEvents: 'none' }} className="InputSelectIcon" />
      {
        !props.disabled && inputSelecionado &&
        <div className="SelectMenuContainer" >
          {
            data.map((el, index) => (
              <div id="SelectOptionContainer"
                className={focus == index ? "focus" : ""}
                onClick={() => {
                  setTextValue(el)
                  setValue(el)
                  setTimeout(() => {
                    props.onSelectOption(el === 'Entrada' ? true : false)
                    setInputSelecionado(false)
                  }, 100)
                }} key={index} >
                <p>{el}</p>
              </div>
            ))
          }
        </div>
      }
    </div>

  )
}


export function Button(props) {
  return (
    <button style={props.style}
      disabled={props.disabled}
      className="buttonContainer"
      onClick={props.onClick}>
      {props.children}
      {props.icon}
    </button>
  )
}
export function ButtonOutline(props) {
  return (
    <button style={{ ...props.style, color: props.color, borderColor: props.color }}
      disabled={props.disabled}
      className="buttonOutlineContainer"
      onClick={props.onClick}>
      {props.children}
      {props.icon}
    </button>
  )
}
export function ButtonText(props) {
  return (
    <button style={{ ...props.style, color: props.color }}
      disabled={props.disabled}
      className="buttonTextContainer"
      onClick={props.onClick}>
      {props.children}
    </button>
  )
}
export function ButtonLoading(props) {
  const isLoading = props.isLoading
  return (
    <button type='submit' style={props.style}
      disabled={props.disabled}
      className="buttonContainer"
      onClick={props.onClick}>
      {
        isLoading ?
          <>
            <>Carregando</>
            <CircularProgress
              className={'noMargin'}
              style={{ color: 'white', marginLeft: 10 }}
              size={22}
            />
          </>
          :
          <>
            {props.children}
            {props.icon}
          </>
      }


    </button>
  )
}
export function CheckBox(props) {

  return (
    <label
      className="containerCheckBox"
      style={{ width: `${props.percento_width}%`, ...props.style }}>
      <p>{props.span}</p>
      <input type="checkbox" disabled={props.disabled}
        checked={props.checked ? 'checked' : ''}
        onChange={(e) => props.onClick && props.onClick(e.target.checked)} />
      <span className="checkBoxCheckMark"></span>
    </label>
  )
}
export function Toggle(props) {
  return (
    <div className='ToggleContainer' style={{ width: `${props.percento_width}%` }}>
      <p>{props.span}</p>
      <input type="checkbox" id="switch"
        disabled={props.disabled}
        checked={props.value}
        className="checkbox" onChange={(e) => props.onClick(e.target.checked)} />
      <label htmlFor="switch" className="toggle"></label>
    </div>
  )
}

