import React, { useEffect, useRef, useState } from "react";
import { Chart } from "react-google-charts";
import { IoChevronForward, IoSearch } from "react-icons/io5";
import api from "../../utils/api";
import { ButtonOutline, InputText } from "../../utils/components/inputs";
import { get, set } from 'localstorage-with-expire'
import { toastError } from "../../utils/components/toast";
import { groupBy, monthsBetween, numberToCurrency } from "../../utils/functions";
import './styles.css'
import { useNavigate } from "react-router-dom";

import { useDownloadExcel } from 'react-export-table-to-excel';

import { SiMicrosoftexcel } from "react-icons/si";
import moment from "moment";

import 'moment/locale/pt-br';

export default function Indicadores() {

  const navigation = useNavigate()
  useEffect(() => {
    moment.locale('pt-br');
    if (!get('fluxo_Grupo_id'))
      navigation('/login')
  }, [])
  const options = {
    legend: 'none',
    vAxes: {
      0: { format: 'currency' },
    }
  }
  const format = [{
    type: "NumberFormat",
    column: 1,
    options: {
      prefix: "R$ ",
      negativeColor: "red",
      negativeParens: true,
      decimalSymbol: ',',
      groupingSymbol: '.'
    }
  }]
  const [de, setDe] = useState(moment(new Date()).format('YYYY-MM-01'))
  const [ate, setAte] = useState(moment(new Date()).format('YYYY-MM-DD'))

  const [variavel, setVariavel] = useState([])
  const [fixo, setFixo] = useState([])
  const [investimento, setInvestimento] = useState([])
  const [dados, setDados] = useState([])
  const [datas, setDatas] = useState([])
  const [catSub, setCatSub] = useState([])
  const [catContas, setCatContas] = useState([])
  const [valorAnterior, setValorAnterior] = useState(0)
  async function indicadores() {
    let Grupo_id = get('fluxo_Grupo_id').Grupo_id


    await api.post('/indicadores', {
      de, ate
    }, { headers: { authorization: Grupo_id } })
      .then(result => {
        setValorAnterior(isNaN(parseFloat(result.headers.valor_anterior)) ? 0 : parseFloat(result.headers.valor_anterior))
        setDatas(monthsBetween(de, ate))
        const organizar = result.data.map(el => {
          let Valor = el.Valor
          if (el.Tipo === 'Saída')
            Valor = parseFloat(el.Valor) * -1
          return { ...el, Valor }
        })
          .sort((a, b) => { return parseFloat(b.Valor) - parseFloat(a.Valor) })

        setDados(organizar)
        const grupoSub = (groupBy(organizar, 'SubConta_nome'))
        setCatSub(grupoSub)
        const grupoConta = (groupBy(organizar, 'Conta_nome'))
        setCatContas(grupoConta)

        let array = []
        const filtragem = result.data.map(el => {
          return [el.Conta_nome, parseFloat(el.Valor)]
        })
        const agrupamento = groupBy(filtragem, 0)
        Object.keys(agrupamento).map(el => {
          array.push([el, agrupamento[el].reduce((ac, ar) => { return ac + ar[1] }, 0)])
        })

        let v = array.filter(el => el[0] === 'Tributos' || el[0] === 'Logística' || el[0] === 'Vendas')

        let f = array.filter(el => el[0] === 'Financeira' || el[0] === 'Ocupação' || el[0] === 'Serviços de Terceiros'
          || el[0] === 'Pessoal' || el[0] === 'Expediente / Materiais')

        let i = array.filter(el => el[0] === 'Marketing' || el[0] === 'Imobilizado' || el[0] === 'Empréstimos e Financiamentos'
          || el[0] === 'Distribuição de Lucros')




        setVariavel(v)
        setFixo(f)
        setInvestimento(i)
        // setTimeout(() => console.log('bla', Object.keys(grupo)[0], grupo['COFINS'], grupo[Object.keys(grupo)[0]]), 1000)
      })
      .catch(err => {
        toastError('Erro ao listar Registros')
        console.log(err)
      })
  }
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Fluxo de Caixa de ' + moment(de).format('DD/MM/YYYY') + " até " + moment(ate).format('DD/MM/YYYY'),
    sheet: 'Fluxo de Caixa'
  })
  return (
    <div className="containerIndicadores">

      <div className="containerHeader">


        {/* <table style={{ display: 'none' }}>
          <tbody>
            <tr>
              <th>Tipo</th>
              <th>Mês</th>
              <th>Data</th>
              <th>Conta</th>
              <th>Sub Conta</th>
              <th>Valor</th>
            </tr>
            {
              dados.map(el => (
                <tr>
                  <td>{el.Tipo}</td>
                  <td>{moment(el.Data).format('MMM')}</td>
                  <td>{el.Data}</td>
                  <td>{el.Conta_nome}</td>
                  <td>{el.SubConta_nome}</td>
                  <td>{el.Valor.replace('.', ',')}</td>
                </tr>
              ))
            }
          </tbody>
        </table> */}
        <h2>Indicadores</h2>
        <div >
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
            <InputText
              span={'De: '}
              percento_width={50}
              value={de}
              onChange={(text) => setDe(text)}
              type={'date'}
            />
            <InputText
              span={'Até: '}
              percento_width={50}
              value={ate}
              onChange={(text) => setAte(text)}
              type={'date'}
            />
            <ButtonOutline style={{ marginTop: 27 }}
              disabled={!de || !ate}
              onClick={() => indicadores()}
              icon={<IoSearch />}></ButtonOutline>
          </div>

          {
            dados.length > 0 && (
              <ButtonOutline style={{ marginTop: 5, float: 'right' }}
                disabled={!de || !ate || dados.length === 0}
                onClick={onDownload}
                color={'var(--green)'}
                icon={<SiMicrosoftexcel />}> Exportar Fluxo de Caixa para Excel </ButtonOutline>

            )
          }
        </div>
      </div>
      {
        variavel.length > 0 && (
          <>
            <h2>Despesas Variáveis</h2>
            <div className="containerGrafico">
              <Chart chartType="ColumnChart"
                width="100%"
                height="400px"
                options={{
                  backgroundColor: 'rgb(250,250,250)',
                  ...options
                }}
                formatters={format}
                data={[
                  ["Conta", "Valor"],
                  ...variavel
                ]} />
              <div className="containerTable">
                <table className="tableGlobal" >
                  <thead>
                    <tr>
                      <th>Conta</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      variavel.map((el, index) => (
                        <tr key={index}>
                          <td>{el[0]}</td>
                          <td>{numberToCurrency(el[1])}</td>
                        </tr>
                      ))
                    }
                    <tr>
                      <th></th>
                      <th>{numberToCurrency(variavel.reduce((ac, ar) => ac + ar[1], 0))}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )

      }
      {
        fixo.length > 0 && (
          <>
            <h2>Despesas Fixas</h2>
            <div className="containerGrafico">
              <Chart chartType="ColumnChart"
                width="100%"
                height="400px"
                options={{
                  backgroundColor: 'rgb(250,250,250)',
                  ...options
                }}
                formatters={format}
                data={[
                  ["Conta", "Valor"],
                  ...fixo
                ]} />
              <div className="containerTable">
                <table className="tableGlobal" >
                  <thead>
                    <tr>
                      <th>Conta</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      fixo.map((el, index) => (
                        <tr key={index}>
                          <td>{el[0]}</td>
                          <td>{numberToCurrency(el[1])}</td>
                        </tr>
                      ))
                    }
                    <tr>
                      <th></th>
                      <th>{numberToCurrency(fixo.reduce((ac, ar) => ac + ar[1], 0))}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )

      }
      {
        investimento.length > 0 && (
          <>
            <h2>Investimentos</h2>
            <div className="containerGrafico">
              <Chart chartType="ColumnChart"
                width="100%"
                height="400px"
                options={{
                  backgroundColor: 'rgb(250,250,250)',
                  ...options
                }}
                formatters={format}
                data={[
                  ["Conta", "Valor"],
                  ...investimento
                ]} />
              <div className="containerTable">
                <table className="tableGlobal" >
                  <thead>
                    <tr>
                      <th>Conta</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      investimento.map((el, index) => (
                        <tr key={index}>
                          <td>{el[0]}</td>
                          <td>{numberToCurrency(el[1])}</td>
                        </tr>
                      ))
                    }
                    <tr>
                      <th></th>
                      <th>{numberToCurrency(investimento.reduce((ac, ar) => ac + ar[1], 0))}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )

      }

      {catSub &&
        <table ref={tableRef} className={"tableExcel"} style={{ display: 'none' }}>
          <tbody>
            <tr>
              <td></td>
              {datas.map((el, i) => <td key={i} style={{ width: 150, backgroundColor: '#ffeaa7' }}>{moment(el).format('MMM - YYYY')}</td>)}
              <td style={{ width: 150, backgroundColor: '#ffeaa7' }}>Total</td>
            </tr>
            {
              <>
                <tr><td></td>{datas.map((DATA, index) => (<td key={index}></td>))}</tr>
                <tr>
                  <th style={{
                    textAlign: 'left',
                    backgroundColor: '#3498db',
                    border: 0
                  }}>Saldo Inicial</th>
                  {
                    datas.map((el, index) =>
                      <th key={index} style={{
                        textAlign: 'left',
                        backgroundColor: '#3498db',
                        border: 0
                      }}>{index === 0 ? valorAnterior.toString().replace('.', ',') : dados.reduce((ac, ar) => {
                        if (moment(ar.Data) < moment(el))
                          return ac + parseFloat(ar.Valor)
                        else
                          return ac
                      }, valorAnterior).toString().replace('.', ',')}</th>
                    )
                  }
                </tr>
              </>
            }
            <tr>
              <th style={{
                textAlign: 'left',
                borderTop: '2px solid black',
                borderBottom: '2px solid black',
                backgroundColor: '#2ecc71'
              }}>Total das Entradas</th>
              {
                datas.map((el, i) =>
                  <th key={i} style={{
                    textAlign: 'left',
                    borderTop: '2px solid black',
                    borderBottom: '2px solid black',
                    backgroundColor: '#2ecc71'
                  }}>{dados.reduce((ac, ar) => {
                    if (ar.Valor > 0 && moment(ar.Data).format('MMM/YYYY') === moment(el).format('MMM/YYYY'))
                      return ac + parseFloat(ar.Valor)
                    else
                      return ac
                  }, 0).toString().replace('.', ',')}</th>
                )
              }
              <th style={{
                textAlign: 'left',
                borderTop: '2px solid black',
                borderBottom: '2px solid black',
                backgroundColor: '#2ecc71'
              }}>{dados.reduce((ac, ar) => {
                if (ar.Valor > 0)
                  return ac + parseFloat(ar.Valor)
                else
                  return ac
              }, 0).toString().replace('.', ',')}</th>
            </tr>

            {

              ['Recebimento de Clientes', 'Não Operacional', 'Fornecedor'].map((el_, index) =>
                <>

                  <tr><td></td>{datas.map((DATA, i) => (<td key={i}></td>))}</tr>
                  <tr>
                    {/* <td>{index}</td> */}
                    <th style={{
                      textAlign: 'left',
                      borderTop: '1px solid black',
                      borderBottom: '2px solid black',
                      backgroundColor: '#ccc'
                    }}>{el_}</th>
                    {
                      datas.map((DATA, index) => (
                        <th key={index} style={{
                          textAlign: 'left',
                          borderTop: '1px solid black',
                          borderBottom: '2px solid black',
                          backgroundColor: '#ccc',
                          color: 'black'
                        }}>{dados.reduce((ac, ar) => {
                          if (ar.Conta_nome === el_ && moment(ar.Data).format('MMM/YYYY') === moment(DATA).format('MMM/YYYY') && parseFloat(ar.Valor) > 0)
                            return ac + parseFloat(ar.Valor)
                          else
                            return ac
                        }, 0).toString().replace('.', ',')

                          }</th>))
                    }
                    <th style={{
                      textAlign: 'left',
                      borderTop: '1px solid black',
                      borderBottom: '2px solid black',
                      backgroundColor: '#ccc',
                      color: 'black'
                    }}>{dados.reduce((ac, ar) => {
                      if (ar.Conta_nome === el_&& parseFloat(ar.Valor) > 0)
                        return ac + parseFloat(ar.Valor)
                      else
                        return ac
                    }, 0).toString().replace('.', ',')

                      }</th>
                  </tr>
                  {
                    Object.keys(catSub)
                      .filter(el => dados.reduce((ac, ar) => ar.Conta_nome === el_ && ar.SubConta_nome === el&& parseFloat(ar.Valor) > 0 ? ac + parseFloat(ar.Valor) : ac, 0) !== 0)
                      .map((el, index) =>
                        <tr key={index}>
                          {/* <td>{index}</td> */}
                          <td style={{ backgroundColor: '#eee' }}>{el}</td>
                          {
                            datas.map((DATA, i) => (
                              <td key={i} style={{ backgroundColor: '#eee', color: catSub[el][0].Tipo === 'Saída' ? '#e74c3c' : 'black' }}>{dados.reduce((ac, ar) => {
                                if (ar.Conta_nome === el_ && ar.SubConta_nome === el && moment(ar.Data).format('MMM/YYYY') === moment(DATA).format('MMM/YYYY')&& parseFloat(ar.Valor) > 0)
                                  return ac + parseFloat(ar.Valor)
                                else
                                  return ac
                              }, 0).toString().replace('.', ',')

                              }</td>))
                          }
                          <td style={{ backgroundColor: '#eee', color: catSub[el][0].Tipo === 'Saída' ? '#e74c3c' : 'black' }}>{dados.reduce((ac, ar) => {
                            if (ar.Conta_nome === el_ && ar.SubConta_nome === el&& parseFloat(ar.Valor) > 0)
                              return ac + parseFloat(ar.Valor)
                            else
                              return ac
                          }, 0).toString().replace('.', ',')

                          }</td>
                        </tr>)
                  }

                </>
              )
            }
            {
              <>
                <tr><td></td>{datas.map((DATA, i) => (<td key={i}></td>))}</tr>
                <tr>
                  <th style={{
                    textAlign: 'left',
                    borderTop: '2px solid black',
                    borderBottom: '2px solid black',
                    backgroundColor: '#e74c3c'
                  }}>Total de Saídas</th>
                  {
                    datas.map((el, i) =>
                      <th key={i} style={{
                        textAlign: 'left',
                        borderTop: '2px solid black',
                        borderBottom: '2px solid black',
                        backgroundColor: '#e74c3c'
                      }}>{dados.reduce((ac, ar) => {
                        if (ar.Valor <= 0 && moment(ar.Data).format('MMM/YYYY') === moment(el).format('MMM/YYYY'))
                          return ac + parseFloat(ar.Valor)
                        else
                          return ac
                      }, 0).toString().replace('.', ',')}</th>
                    )
                  }
                  <th style={{
                    textAlign: 'left',
                    borderTop: '2px solid black',
                    borderBottom: '2px solid black',
                    backgroundColor: '#e74c3c'
                  }}>{dados.reduce((ac, ar) => {
                    if (ar.Valor <= 0)
                      return ac + parseFloat(ar.Valor)
                    else
                      return ac
                  }, 0).toString().replace('.', ',')}</th>
                </tr>
              </>
            }
            {
              <>

                <tr><td></td>{datas.map((DATA, i) => (<td key={i}></td>))}</tr>
                <tr>
                  <th style={{
                    textAlign: 'left',
                    backgroundColor: '#e74c3c',
                    border: 0
                  }}>Saídas - Operacional</th>
                  {
                    datas.map((el, i) =>
                      <th key={i} style={{
                        textAlign: 'left',
                        backgroundColor: '#e74c3c',
                        border: 0
                      }}>{dados.reduce((ac, ar) => {
                        if (['Fornecedor', 'Tributos', 'Vendas', 'Logística', 'Financeira', 'Ocupação', 'Serviços de Terceiros', 'Pessoal', 'Expediente / Materiais'].filter(el => ar.Conta_nome === el).length > 0 && moment(ar.Data).format('MMM/YYYY') === moment(el).format('MMM/YYYY') && parseFloat(ar.Valor) < 0)
                          return ac + parseFloat(ar.Valor)
                        else
                          return ac
                      }, 0).toString().replace('.', ',')}</th>
                    )
                  }
                  <th style={{
                    textAlign: 'left',
                    backgroundColor: '#e74c3c',
                    border: 0
                  }}>{dados.reduce((ac, ar) => {
                    if (['Fornecedor', 'Tributos', 'Vendas', 'Logística', 'Financeira', 'Ocupação', 'Serviços de Terceiros', 'Pessoal', 'Expediente / Materiais'].filter(el => ar.Conta_nome === el).length > 0 && parseFloat(ar.Valor) < 0)
                      return ac + parseFloat(ar.Valor)
                    else
                      return ac
                  }, 0).toString().replace('.', ',')}</th>
                </tr>
              </>
            }
            {
              <>
                <tr><td></td>{datas.map((DATA, i) => (<td key={i}></td>))}</tr>
                <tr><td></td>{datas.map((DATA, i) => (<td key={i}></td>))}</tr>

                <tr>
                  {/* <td>{index}</td> */}
                  <th style={{
                    textAlign: 'left',
                    borderTop: '1px solid black',
                    borderBottom: '2px solid black',
                    backgroundColor: '#e67e22'
                  }}>Variável</th>
                  {
                    datas.map((DATA, i) => (
                      <th key={i} style={{
                        textAlign: 'left',
                        borderTop: '1px solid black',
                        borderBottom: '2px solid black',
                        backgroundColor: '#e67e22',
                        color: 'black'
                      }}>{dados.reduce((ac, ar) => {
                        if (['Fornecedor', 'Tributos', 'Vendas', 'Logística',].filter(el => ar.Conta_nome === el).length > 0 && moment(ar.Data).format('MMM/YYYY') === moment(DATA).format('MMM/YYYY') && parseFloat(ar.Valor) < 0)
                          return ac + parseFloat(ar.Valor)
                        else
                          return ac
                      }, 0).toString().replace('.', ',')

                        }</th>))
                  }
                  <th style={{
                    textAlign: 'left',
                    borderTop: '1px solid black',
                    borderBottom: '2px solid black',
                    backgroundColor: '#e67e22',
                    color: 'black'
                  }}>{dados.reduce((ac, ar) => {
                    if (['Fornecedor', 'Tributos', 'Vendas', 'Logística'].filter(el => ar.Conta_nome === el).length > 0 && parseFloat(ar.Valor) < 0)
                      return ac + parseFloat(ar.Valor)
                    else
                      return ac
                  }, 0).toString().replace('.', ',')

                    }</th>
                </tr>
              </>
            }

            {
              ['Fornecedor', 'Tributos', 'Vendas', 'Logística'].map((el_, index) =>
                <>
                  <tr><td></td>{datas.map((DATA, i) => (<td key={i}></td>))}</tr>
                  <tr>
                    {/* <td>{index}</td> */}
                    <th style={{
                      textAlign: 'left',
                      borderTop: '1px solid black',
                      borderBottom: '2px solid black',
                      backgroundColor: '#ccc'
                    }}>{el_}</th>
                    {
                      datas.map((DATA, i) => (
                        <th key={i} style={{
                          textAlign: 'left',
                          borderTop: '1px solid black',
                          borderBottom: '2px solid black',
                          backgroundColor: '#ccc',
                          color: 'black'
                        }}>{dados.reduce((ac, ar) => {
                          if (ar.Conta_nome === el_ && moment(ar.Data).format('MMM/YYYY') === moment(DATA).format('MMM/YYYY') && parseFloat(ar.Valor) < 0)
                            return ac + parseFloat(ar.Valor)
                          else
                            return ac
                        }, 0).toString().replace('.', ',')

                          }</th>))
                    }
                    <th style={{
                      textAlign: 'left',
                      borderTop: '1px solid black',
                      borderBottom: '2px solid black',
                      backgroundColor: '#ccc',
                      color: 'black'
                    }}>{dados.reduce((ac, ar) => {
                      if (ar.Conta_nome === el_ && parseFloat(ar.Valor) < 0)
                        return ac + parseFloat(ar.Valor)
                      else
                        return ac
                    }, 0).toString().replace('.', ',')

                      }</th>
                  </tr>
                  {
                    Object.keys(catSub)
                      .filter(el => dados.reduce((ac, ar) => ar.Conta_nome === el_ && ar.SubConta_nome === el && parseFloat(ar.Valor) < 0 ? ac + parseFloat(ar.Valor) : ac, 0) !== 0)
                      .map((el, index) =>
                        <tr key={index}>
                          {/* <td>{index}</td> */}
                          <td style={{ backgroundColor: '#eee' }}>{el}</td>
                          {
                            datas.map((DATA, i) => (
                              <td key={i} style={{ backgroundColor: '#eee', color: catSub[el][0].Tipo === 'Saída' ? '#e74c3c' : 'black' }}>{dados.reduce((ac, ar) => {
                                if (ar.Conta_nome === el_ && ar.SubConta_nome === el && moment(ar.Data).format('MMM/YYYY') === moment(DATA).format('MMM/YYYY') && parseFloat(ar.Valor) < 0)
                                  return ac + parseFloat(ar.Valor)
                                else
                                  return ac
                              }, 0).toString().replace('.', ',')

                              }</td>))
                          }
                          <td style={{ backgroundColor: '#eee', color: catSub[el][0].Tipo === 'Saída' ? '#e74c3c' : 'black' }}>{dados.reduce((ac, ar) => {
                            if (ar.Conta_nome === el_ && ar.SubConta_nome === el && parseFloat(ar.Valor) < 0)
                              return ac + parseFloat(ar.Valor)
                            else
                              return ac
                          }, 0).toString().replace('.', ',')

                          }</td>
                        </tr>)
                  }
                </>
              )
            }
            {

              <>
                <tr><td></td>{datas.map((DATA, i) => (<td key={i}></td>))}</tr>
                <tr><td></td>{datas.map((DATA, i) => (<td key={i}></td>))}</tr>

                <tr>
                  {/* <td>{index}</td> */}
                  <th style={{
                    textAlign: 'left',
                    borderTop: '1px solid black',
                    borderBottom: '2px solid black',
                    backgroundColor: '#3498db'
                  }}>Fixo</th>
                  {
                    datas.map((DATA, i) => (
                      <th key={i} style={{
                        textAlign: 'left',
                        borderTop: '1px solid black',
                        borderBottom: '2px solid black',
                        backgroundColor: '#3498db',
                        color: 'black'
                      }}>{dados.reduce((ac, ar) => {
                        if (['Financeira', 'Ocupação', 'Serviços de Terceiros', 'Pessoal', 'Expediente / Materiais'].filter(el => ar.Conta_nome === el).length > 0 && moment(ar.Data).format('MMM/YYYY') === moment(DATA).format('MMM/YYYY'))
                          return ac + parseFloat(ar.Valor)
                        else
                          return ac
                      }, 0).toString().replace('.', ',')

                        }</th>))
                  }
                  <th style={{
                    textAlign: 'left',
                    borderTop: '1px solid black',
                    borderBottom: '2px solid black',
                    backgroundColor: '#3498db',
                    color: 'black'
                  }}>{dados.reduce((ac, ar) => {
                    if (['Financeira', 'Ocupação', 'Serviços de Terceiros', 'Pessoal', 'Expediente / Materiais'].filter(el => ar.Conta_nome === el).length > 0)
                      return ac + parseFloat(ar.Valor)
                    else
                      return ac
                  }, 0).toString().replace('.', ',')

                    }</th>
                </tr>

              </>
            }
            {
              ['Financeira', 'Ocupação', 'Serviços de Terceiros', 'Pessoal', 'Expediente / Materiais'].map((el_, index) =>
                <>
                  <tr><td></td>{datas.map((DATA, i) => (<td key={i}></td>))}</tr>
                  <tr>
                    {/* <td>{index}</td> */}
                    <th style={{
                      textAlign: 'left',
                      borderTop: '1px solid black',
                      borderBottom: '2px solid black',
                      backgroundColor: '#ccc'
                    }}>{el_}</th>
                    {
                      datas.map((DATA, index) => (
                        <th key={index} style={{
                          textAlign: 'left',
                          borderTop: '1px solid black',
                          borderBottom: '2px solid black',
                          backgroundColor: '#ccc',
                          color: 'black'
                        }}>{dados.reduce((ac, ar) => {
                          if (ar.Conta_nome === el_ && moment(ar.Data).format('MMM/YYYY') === moment(DATA).format('MMM/YYYY'))
                            return ac + parseFloat(ar.Valor)
                          else
                            return ac
                        }, 0).toString().replace('.', ',')

                          }</th>))
                    }
                    <th style={{
                      textAlign: 'left',
                      borderTop: '1px solid black',
                      borderBottom: '2px solid black',
                      backgroundColor: '#ccc',
                      color: 'black'
                    }}>{dados.reduce((ac, ar) => {
                      if (ar.Conta_nome === el_)
                        return ac + parseFloat(ar.Valor)
                      else
                        return ac
                    }, 0).toString().replace('.', ',')

                      }</th>
                  </tr>
                  {
                    Object.keys(catSub)
                      .filter(el => dados.reduce((ac, ar) => ar.Conta_nome === el_ && ar.SubConta_nome === el ? ac + parseFloat(ar.Valor) : ac, 0) !== 0)
                      .map((el, index) =>
                        <tr key={index}>
                          {/* <td>{index}</td> */}
                          <td style={{ backgroundColor: '#eee' }}>{el}</td>
                          {
                            datas.map((DATA, i) => (
                              <td key={i} style={{ backgroundColor: '#eee', color: catSub[el][0].Tipo === 'Saída' ? '#e74c3c' : 'black' }}>{dados.reduce((ac, ar) => {
                                if (ar.Conta_nome === el_ && ar.SubConta_nome === el && moment(ar.Data).format('MMM/YYYY') === moment(DATA).format('MMM/YYYY'))
                                  return ac + parseFloat(ar.Valor)
                                else
                                  return ac
                              }, 0).toString().replace('.', ',')

                              }</td>))
                          }
                          <td style={{ backgroundColor: '#eee', color: catSub[el][0].Tipo === 'Saída' ? '#e74c3c' : 'black' }}>{dados.reduce((ac, ar) => {
                            if (ar.Conta_nome === el_ && ar.SubConta_nome === el)
                              return ac + parseFloat(ar.Valor)
                            else
                              return ac
                          }, 0).toString().replace('.', ',')

                          }</td>
                        </tr>)
                  }
                </>
              )
            }
            {

              <>
                <tr><td></td>{datas.map((DATA, i) => (<td key={i}></td>))}</tr>
                <tr><td></td>{datas.map((DATA, i) => (<td key={i}></td>))}</tr>

                <tr>
                  {/* <td>{index}</td> */}
                  <th style={{
                    textAlign: 'left',
                    borderTop: '1px solid black',
                    borderBottom: '2px solid black',
                    backgroundColor: '#f1c40f'
                  }}>Investimentos</th>
                  {
                    datas.map((DATA, i) => (
                      <th key={i} style={{
                        textAlign: 'left',
                        borderTop: '1px solid black',
                        borderBottom: '2px solid black',
                        backgroundColor: '#f1c40f',
                        color: 'black'
                      }}>{dados.reduce((ac, ar) => {
                        if (['Marketing', 'Imobilizado', 'Empréstimos e Financiamentos', 'Distribuição de Lucros'].filter(el => ar.Conta_nome === el).length > 0 && moment(ar.Data).format('MMM/YYYY') === moment(DATA).format('MMM/YYYY'))
                          return ac + parseFloat(ar.Valor)
                        else
                          return ac
                      }, 0).toString().replace('.', ',')

                        }</th>))
                  }
                  <th style={{
                    textAlign: 'left',
                    borderTop: '1px solid black',
                    borderBottom: '2px solid black',
                    backgroundColor: '#f1c40f',
                    color: 'black'
                  }}>{dados.reduce((ac, ar) => {
                    if (['Marketing', 'Imobilizado', 'Empréstimos e Financiamentos', 'Distribuição de Lucros'].filter(el => ar.Conta_nome === el).length > 0)
                      return ac + parseFloat(ar.Valor)
                    else
                      return ac
                  }, 0).toString().replace('.', ',')

                    }</th>
                </tr>

              </>
            }
            {
              ['Marketing', 'Imobilizado', 'Empréstimos e Financiamentos', 'Distribuição de Lucros'].map((el_, index) =>
                <>
                  <tr><td></td>{datas.map((DATA, index) => (<td key={index}></td>))}</tr>
                  <tr>
                    {/* <td>{index}</td> */}
                    <th style={{
                      textAlign: 'left',
                      borderTop: '1px solid black',
                      borderBottom: '2px solid black',
                      backgroundColor: '#ccc'
                    }}>{el_}</th>
                    {
                      datas.map((DATA, index) => (
                        <th key={index} style={{
                          textAlign: 'left',
                          borderTop: '1px solid black',
                          borderBottom: '2px solid black',
                          backgroundColor: '#ccc',
                          color: 'black'
                        }}>{dados.reduce((ac, ar) => {
                          if (ar.Conta_nome === el_ && moment(ar.Data).format('MMM/YYYY') === moment(DATA).format('MMM/YYYY'))
                            return ac + parseFloat(ar.Valor)
                          else
                            return ac
                        }, 0).toString().replace('.', ',')

                          }</th>))
                    }
                    <th style={{
                      textAlign: 'left',
                      borderTop: '1px solid black',
                      borderBottom: '2px solid black',
                      backgroundColor: '#ccc',
                      color: 'black'
                    }}>{dados.reduce((ac, ar) => {
                      if (ar.Conta_nome === el_)
                        return ac + parseFloat(ar.Valor)
                      else
                        return ac
                    }, 0).toString().replace('.', ',')

                      }</th>
                  </tr>
                  {
                    Object.keys(catSub)
                      .filter(el => dados.reduce((ac, ar) => ar.Conta_nome === el_ && ar.SubConta_nome === el ? ac + parseFloat(ar.Valor) : ac, 0) !== 0)
                      .map((el, index) =>
                        <tr key={index}>
                          {/* <td>{index}</td> */}
                          <td style={{ backgroundColor: '#eee' }}>{el}</td>
                          {
                            datas.map((DATA, i) => (
                              <td key={i} style={{ backgroundColor: '#eee', color: '#e74c3c' }}>
                                {dados.reduce((ac, ar) => {
                                  if (ar.Conta_nome === el_ && ar.SubConta_nome === el && moment(ar.Data).format('MMM/YYYY') === moment(DATA).format('MMM/YYYY'))
                                    return ac + parseFloat(ar.Valor)
                                  else
                                    return ac
                                }, 0).toString().replace('.', ',')

                                }</td>))
                          }
                          <td style={{ backgroundColor: '#eee', color: '#e74c3c' }}>
                            {dados.reduce((ac, ar) => {
                              if (ar.Conta_nome === el_ && ar.SubConta_nome === el)
                                return ac + parseFloat(ar.Valor)
                              else
                                return ac
                            }, 0).toString().replace('.', ',')

                            }</td>
                        </tr>)
                  }
                </>
              )
            }
            {
              <>
                <tr><td></td>{datas.map((DATA, index) => (<td key={index}></td>))}</tr>
                <tr>
                  <th style={{
                    textAlign: 'left',
                    backgroundColor: '#3498db',
                    border: 0
                  }}>Fluxo de Caixa</th>
                  {
                    datas.map((el, index) =>
                      <th key={index} style={{
                        textAlign: 'left',
                        backgroundColor: '#3498db',
                        border: 0
                      }}>{dados.reduce((ac, ar) => {
                        if (moment(ar.Data).format('MMM/YYYY') === moment(el).format('MMM/YYYY'))
                          return ac + parseFloat(ar.Valor)
                        else
                          return ac
                      }, 0).toString().replace('.', ',')}</th>
                    )
                  }
                  <th style={{
                    textAlign: 'left',
                    backgroundColor: '#3498db',
                    border: 0
                  }}>{dados.reduce((ac, ar) => { return ac + parseFloat(ar.Valor) }, valorAnterior).toString().replace('.', ',')}</th>
                </tr>
              </>
            }
            {
              <>
                <tr><td></td>{datas.map((DATA, index) => (<td key={index}></td>))}</tr>
                <tr>
                  <th style={{
                    textAlign: 'left',
                    backgroundColor: '#3498db',
                    border: 0
                  }}>Saldo de Caixa</th>
                  {
                    datas.map((el, index) =>
                      <th key={index} style={{
                        textAlign: 'left',
                        backgroundColor: '#3498db',
                        border: 0
                      }}>{index === 0 ? dados.reduce((ac, ar) => {
                        if (moment(ar.Data).format('MMM/YYYY') === moment(el).format('MMM/YYYY'))
                          return ac + parseFloat(ar.Valor)
                        else
                          return ac
                      }, valorAnterior).toString().replace('.', ',') : dados.reduce((ac, ar) => {
                        if (moment(ar.Data) < moment(el) || moment(ar.Data).format('MMM/YYYY') === moment(datas[index]).format('MMM/YYYY'))
                          return ac + parseFloat(ar.Valor)
                        else
                          return ac
                      }, valorAnterior).toString().replace('.', ',')}</th>
                    )
                  }
                </tr>
              </>
            }
            {

              //   Array.from(Array(Object.keys(catContas).length).keys()).map((el_, index) =>
              //     <>
              //       <tr><td></td>{datas.map(DATA => (<td></td>))}</tr>
              //       <tr>
              //         {/* <td>{index}</td> */}
              //         <th style={{
              //           textAlign: 'left',
              //           borderTop: '1px solid black',
              //           borderBottom: '2px solid black',
              //           backgroundColor: '#ccc'
              //         }}>{Object.keys(catContas)[el_]}</th>
              //         {
              //           datas.map(DATA => (
              //             <th style={{
              //               textAlign: 'left',
              //               borderTop: '1px solid black',
              //               borderBottom: '2px solid black',
              //               backgroundColor: '#ccc',
              //               color: catContas[Object.keys(catContas)[el_]][0].Tipo === 'Saída' ? '#e74c3c' : 'black'
              //             }}>{dados.reduce((ac, ar) => {
              //               if (ar.Conta_nome === Object.keys(catContas)[el_] && moment(ar.Data).format('MMM/YYYY') === moment(DATA).format('MMM/YYYY'))
              //                 return ac + parseFloat(ar.Valor)
              //               else
              //                 return ac
              //             }, 0)

              //               }</th>))
              //         }
              //       </tr>
              //       <tr><td></td>{datas.map(DATA => (<td></td>))}</tr>
              //       {
              //         Array.from(Array(Object.keys(catSub).length).keys())
              //           .filter(el => catSub[Object.keys(catSub)[el]][0].Conta_nome === Object.keys(catContas)[el_])
              //           .map((el, index) =>
              //             <tr>
              //               {/* <td>{index}</td> */}
              //               <td style={{ backgroundColor: '#eee' }}>{Object.keys(catSub)[el]}</td>
              //               {
              //                 datas.map(DATA => (
              //                   <td style={{ backgroundColor: '#eee', color: catSub[Object.keys(catSub)[el]][0].Tipo === 'Saída' ? '#e74c3c' : 'black' }}>{dados.reduce((ac, ar) => {
              //                     if (ar.Conta_nome === Object.keys(catContas)[el_] && ar.SubConta_nome === Object.keys(catSub)[el] && moment(ar.Data).format('MMM/YYYY') === moment(DATA).format('MMM/YYYY'))
              //                       return ac + parseFloat(ar.Valor)
              //                     else
              //                       return ac
              //                   }, 0)

              //                   }</td>))
              //               }
              //             </tr>)
              //       }
              //     </>
              //   )


              // 
            }
          </tbody>
        </table>
      }
    </div>
  );
}
