import React, { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line
import { get, set } from 'localstorage-with-expire'
import { ButtonLoading, ButtonText, InputCurrency, InputText, InputTextArea, Select, SelectCreate } from '../../utils/components/inputs'
import './styles.css'
import '../../utils/global-style.css'
import { IoSend, IoTrash } from 'react-icons/io5';
import moment from 'moment';
import api from '../../utils/api';
import { toastError, toastSuccess } from '../../utils/components/toast';
import { useNavigate, useParams } from 'react-router-dom';
import { numberToCurrency } from '../../utils/functions';
function EditarRegistro() {
  const [loading, setLoading] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [loadingConta, setLoadingConta] = useState(false)
  const [loadingSubConta, setLoadingSubConta] = useState(false)
  const [Data, setData] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const [Tipo, setTipo] = useState(true)
  const [Conta, setConta] = useState([])
  const [ContaDefault, setContaDefault] = useState([])
  const [Conta_id, setConta_id] = useState(null)
  const [SubConta_id, setSubConta_id] = useState(null)
  const [Conta_nome, setConta_nome] = useState(null)
  const [SubConta_nome, setSubConta_nome] = useState(null)
  const [SubConta, setSubConta] = useState([])
  const [SubContaDefault, setSubContaDefault] = useState([])
  const [Valor, setValor] = useState(null)
  const [Observacao, setObservacao] = useState('')
  const params = useParams()
  const navigation = useNavigate()
  const [loadingEmpresa, setLoadingEmpresa] = useState(false)
  const [Empresa, setEmpresa] = useState([])
  const [EmpresaDefault, setEmpresaDefault] = useState([])
  const [Empresa_id, setEmpresa_id] = useState(null)
  const [Empresa_nome, setEmpresa_nome] = useState(null)
  const inputEmpresa = useRef()
  const inputConta = useRef()
  const inputSubConta = useRef()
  const inputValor = useRef()

  const [Grupo_id, setGrupo_id] = useState(null)
  useEffect(() => {
    if (!get('fluxo_Grupo_id'))
      navigation('/login')
    else {
      setGrupo_id(get('fluxo_Grupo_id').Grupo_id)
      getRegistro()
    }
    // eslint-disable-next-line
  }, [])
  async function listarEmpresas() {
    let Grupo_id = get('fluxo_Grupo_id').Grupo_id
    await api.get('/listarEmpresas', { headers: { authorization: Grupo_id } })
      .then(result => {
        let array = result.data.map(el => {
          return {
            value: el.Empresa_id,
            Nome: el.Empresa_nome,
            Elemento: el
          }
        })
        setEmpresa(array)
        setEmpresaDefault(array)
        if (array.length === 1) {
          setEmpresa_id(array[0].value)
          setEmpresa_nome(array[0].Nome)
        }
      })
      .catch(err => {
        toastError('Erro ao pegar dados')
        console.log(err)
      })
  }
  async function getRegistro() {
    let Grupo_id = get('fluxo_Grupo_id').Grupo_id
    setLoading(true)
    await api.get('/getRegistro?id=' + params.id, { headers: { authorization: Grupo_id } })
      .then(result => {
        setData(result.data.Data)
        setTipo(result.data.Tipo === 'Entrada' ? true : false)
        setEmpresa_id(result.data.Empresa_id)
        setConta_id(result.data.Conta_id)
        setSubConta_id(result.data.SubConta_id)
        setEmpresa_nome(result.data.Empresa_nome)
        setConta_nome(result.data.Conta_nome)
        setSubConta_nome(result.data.SubConta_nome)
        setValor(result.data.Valor)
        inputValor.current.value = numberToCurrency(result.data.Valor)
        setObservacao(result.data.Observacao)
        listarContas()
        listarSubContas()
        listarEmpresas()
      })
      .catch(err => {
        toastError('Erro ao pegar dados')
        console.log(err)
      })
    setLoading(false)
  }
  async function listarContas() {
    let Grupo_id = get('fluxo_Grupo_id').Grupo_id
    await api.get('/listarContas', { headers: { authorization: Grupo_id } })
      .then(result => {
        let array = result.data.map(el => {
          return {
            value: el.Conta_id,
            Nome: el.Conta_nome,
            Elemento: el
          }
        })
        setConta(array)
        setContaDefault(array)
        filterConta(array)
      })
      .catch(err => {
        toastError('Erro ao pegar dados')
        console.log(err)
      })
  }
  async function listarSubContas() {
    let Grupo_id = get('fluxo_Grupo_id').Grupo_id
    await api.get('/listarSubContas', { headers: { authorization: Grupo_id } })
      .then(result => {
        let array = result.data.map(el => {
          return {
            value: el.SubConta_id,
            Nome: el.SubConta_nome,
            Elemento: el
          }
        })
        setSubContaDefault(array)
      })
      .catch(err => {
        toastError('Erro ao pegar dados')
        console.log(err)
      })
  }
  async function filterConta(conta = ContaDefault, tipo = Tipo) {
    tipo = tipo ? 'Entrada' : 'Saída'
    setConta(conta.filter(el => el.Elemento.Tipo === tipo))
  }
  async function pesquisarEmpresa(text) {
    let array = EmpresaDefault.filter(el => el.Elemento.Empresa_nome.toLowerCase().search(text.toLowerCase()) >= 0)
    if (text.trim().length <= 0) {
      setEmpresa_id(null)
      setEmpresa_nome(null)
    } else {
      setEmpresa(array)
    }
    setLoadingEmpresa(false)
  }
  async function pesquisarConta(text, tipo = Tipo) {
    tipo = tipo ? 'Entrada' : 'Saída'
    let array = ContaDefault.filter(el => el.Elemento.Tipo === tipo && el.Elemento.Conta_nome.toLowerCase().search(text.toLowerCase()) >= 0)
    if (text.trim().length <= 0) {
      setConta_id(null)
      setConta_nome(null)
      filterConta(ContaDefault)
      setSubConta_id(null)
    } else {
      setConta(array)
    }
    setLoadingConta(false)
  }
  async function filterSubConta(subconta = SubContaDefault, conta_id = Conta_id) {
    setSubConta(subconta.filter(el => el.Elemento.Conta_id === conta_id))
  }
  async function pesquisarSubConta(text, conta_id = Conta_id) {
    let array = SubContaDefault.filter(el => el.Elemento.Conta_id === conta_id && el.Elemento.SubConta_nome.toLowerCase().search(text.toLowerCase()) >= 0)
    if (text.trim().length <= 0) {
      filterSubConta(SubContaDefault)
    } else {
      setSubConta(array)
    }
    setLoadingSubConta(false)
  }
  async function editarRegistro() {
    setLoading(true)
    await api.post('/editarRegistro?id=' + params.id, {
      Tipo: Tipo ? 'Entrada' : 'Saída',
      Grupo_id,
      Data,
      Empresa_id,
      Empresa_nome,
      Conta_id,
      SubConta_id,
      Conta_nome,
      SubConta_nome,
      // eslint-disable-next-line
      Valor: Valor.replace(/[^0-9\.]/g, ''),
      Observacao
    }, { headers: { authorization: Grupo_id } })
      .then(result => {
        setTipo(true)
        setEmpresa_id(null)
        setConta_id(null)
        setSubConta_id(null)
        setValor(null)
        inputValor.current.value = ''
        setObservacao('')
        navigation('/')
        toastSuccess('Atualizado com sucesso')
      })
      .catch(err => {
        toastError('Erro ao atualizar')
        console.log(err)
      })
    setLoading(false)
  }
  async function deletarRegistro() {
    setLoading(true)
    await api.post('/deletarRegistro?id=' + params.id, {}, { headers: { authorization: Grupo_id } })
      .then(result => {
        setTipo(true)
        setConta_id(null)
        setSubConta_id(null)
        setValor(null)
        inputValor.current.value = ''
        setObservacao('')
        navigation('/')
        toastSuccess('Deletado com sucesso')
      })
      .catch(err => {
        toastError('Erro ao deletar')
        console.log(err)
      })
    setLoading(false)
  }
  return (
    <div className='containerNovoRegistro' data-theme={'light'}>
      <div className='containerInputs'>
        <h3>Editar Lançamento</h3>
        <div style={{ display: 'flex' }}>
          <InputText
            disabled={loading}
            type={"date"}
            required
            span={"Data"}
            percento_width={'50'}
            value={Data}
            onChange={(el) => {
              setData(el)
            }}
          />
          <Select
            disabled={loading}
            required
            value={Tipo}
            onSelectOption={(el) => {
              setTipo(el)
              filterConta(ContaDefault, el)
              setConta_id(null)
              setSubConta_id(null)
              inputEmpresa.current.focus()
            }}
            span={'Tipo'}
          />
        </div>

        <SelectCreate
          ref_={inputEmpresa}
          required
          disabled={loading}
          ignoreLength
          defaultData={Empresa}
          data={Empresa}
          onlyNome
          value={Empresa_id}
          updateText={Empresa_id !== null ? Empresa_nome : null}
          onClear={() => {
            setEmpresa_id(null)
            setEmpresa_nome(null)
            // filterEmpresa(EmpresaDefault)
            inputEmpresa.current.focus()
          }}
          onSelectOption={(el) => {
            setEmpresa([])
            setEmpresa_id(el.Empresa_id)
            setEmpresa_nome(el.Empresa_nome)
            setTimeout(() => inputConta.current.focus(), 300)
          }}
          span={'Empresa'}
          onChange={(texto) => pesquisarEmpresa(texto, Tipo)}
          setLoading={setLoadingEmpresa}
          loading={loadingEmpresa}
          onCreate={() => {
            setEmpresa_nome(inputEmpresa.current.value)
            setEmpresa_id(0)
            setTimeout(() => inputConta.current.focus(), 300)
          }}
        />
        <SelectCreate
        dontCreate
          ref_={inputConta}
          required
          disabled={loading}
          ignoreLength
          defaultData={Conta}
          data={Conta}
          onlyNome
          value={Conta_id}
          updateText={Conta_id !== null ? Conta_nome : null}
          onClear={() => {
            setConta_id(null)
            setConta_nome(null)
            filterConta(ContaDefault)
            setSubConta_id(null)
            inputConta.current.focus()
          }}
          onSelectOption={(el) => {
            setConta([])
            setConta_id(el.Conta_id)
            setConta_nome(el.Conta_nome)
            filterSubConta(SubContaDefault, el.Conta_id)
            setSubConta_id(null)
            setTimeout(() => inputSubConta.current.focus(), 300)
          }}
          span={'Conta'}
          onChange={(texto) => pesquisarConta(texto, Tipo)}
          setLoading={setLoadingConta}
          loading={loadingConta}
          onCreate={() => {
            setConta_nome(inputConta.current.value)
            setConta_id(0)
          }}
        />
        <SelectCreate
          ref_={inputSubConta}
          required
          ignoreLength
          disabled={Conta_id === null || loading}
          span={'Sub Conta'}
          defaultData={SubConta}
          data={SubConta}
          onlyNome
          value={SubConta_id}
          updateText={SubConta_id !== null ? SubConta_nome : null}
          onClear={() => {
            setSubConta_id(null)
            setSubConta_nome(null)
            filterSubConta()
            inputSubConta.current.focus()
          }}
          onSelectOption={(el) => {
            setSubConta([])
            setSubConta_id(el.SubConta_id)
            setSubConta_nome(el.SubConta_nome)
            inputValor.current.focus()
          }}
          onChange={(texto) => pesquisarSubConta(texto, Conta_id)}
          setLoading={setLoadingSubConta}
          loading={loadingSubConta}
          onCreate={() => {
            setSubConta_nome(inputSubConta.current.value)
            setSubConta_id(0)
          }}
        />
        <InputCurrency
          disabled={loading}
          ref_={inputValor}
          required
          prefix={'R$'}
          span={'Valor'}
          value={Valor}
          onPressEnter={() => inputValor.current.blur()}
          onChange={el => {

            setValor(el)
          }}
        />
        <InputTextArea
          disabled={loading}
          required
          span={'Observação'}
          value={Observacao}
          onChange={el => setObservacao(el)}
        />

        <div style={{ display: "flex", alignSelf: 'flex-end' }}>
          <ButtonText
            onClick={() => setModalDelete(true)}
            style={{ margin: 10 }}
            icon={<IoSend />}
          >
            Deletar
          </ButtonText>
          <ButtonLoading
            isLoading={loading}
            disabled={loading || Valor <= 0 || SubConta_id === null || Empresa_id === null}
            onClick={() => editarRegistro()}
            style={{ margin: 10 }}
            icon={<IoSend />}
          >
            Atualizar
          </ButtonLoading>
        </div>
      </div>
      {
        modalDelete && (
          <div className='modalDelete'>
            <div className='backgroundBlack'></div>
            <div className='containerItem'>
              <h3>Deletar Registro?</h3>
              <div style={{ display: 'flex' }}>
                <ButtonText onClick={() => setModalDelete(false)}>Não</ButtonText>
                <ButtonLoading onClick={() => deletarRegistro()} icon={<IoTrash />} >
                  Sim
                </ButtonLoading>

              </div>

            </div>
          </div>
        )
      }
    </div >
  )
};

export default EditarRegistro;