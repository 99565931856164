/* eslint-disable */
import React, { useEffect, useState } from 'react';
import './styles/navigation.css'
import '../global-style.css'
import { IoAddOutline, IoList } from 'react-icons/io5';
import { Link, useLocation, useNavigate, useNavigation, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineBarChart } from 'react-icons/ai';


function Navigation(props) {
  const params = useLocation()
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {props.children}
      {params.pathname !== '/login' &&
        <div className='containerNavigation'>
          <Link to={'/novoRegistro'} className='itemNavigation' id={params.pathname === '/novoRegistro' ? "itemSelected" : ''}>
            <IoAddOutline size={20} />
            <p>Novo Registro</p>
          </Link>
          <Link to={'/'} className='itemNavigation' id={params.pathname === '/' ? "itemSelected" : ''}>
            <IoList size={20} />
            <p>Registros</p>
          </Link>
          <Link to={'/indicadores'} className='itemNavigation' id={params.pathname === '/indicadores' ? "itemSelected" : ''}>
            <AiOutlineBarChart size={20} />
            <p>Indicadores</p>
          </Link>
        </div>
      }
    </div>
  )
}

export default Navigation;