import React, { useState } from 'react';
import { IoSend } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';
import { ButtonLoading, InputText } from '../../utils/components/inputs';
import { get, set } from 'localstorage-with-expire'

// import { Container } from './styles';
import './styles.css'
import { toastError } from '../../utils/components/toast';
function Login() {
  const [senha, setSenha] = useState('')
  const navigation = useNavigate()
  async function login() {
    await api.post('/login', {
      Senha: senha
    })
      .then(result => {
        set('fluxo_Grupo_id', { Grupo_id: result.data }, {
          minute: 60
        })
        navigation('/')
      })
      .catch(err => {
        toastError('Erro ao realizar login')
        console.log(err)
      })
  }
  return (
    <div className='containerLogin'>
      <div className='containerInputsLogin'>
        <InputText
          required={true}
          onPressEnter={() =>senha.length >= 6 && login()}
          value={senha}
          onChange={(text) => setSenha(text)}
          type='password'
          span='Digite seu código'
        />
        <ButtonLoading
          disabled={senha.length < 6}
          onClick={() => login()}
          style={{ width: '100px', marginTop: 26 }}
          icon={<IoSend />}
        >
          Login
        </ButtonLoading>
      </div>
    </div>
  );
}

export default Login;