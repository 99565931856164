import moment from 'moment';
import { FileUploader } from "react-drag-drop-files";
import './styles.css';
import React, { useEffect, useRef, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../utils/api';
import TableList from '../../utils/components/tableList';
import { toastError, toastSuccess } from '../../utils/components/toast';
import { numberToCurrency } from '../../utils/functions';
// eslint-disable-next-line
import { get, set } from 'localstorage-with-expire'
import { ButtonLoading, ButtonOutline, InputText, SelectCreate } from '../../utils/components/inputs';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { SiMicrosoftexcel } from "react-icons/si";
import SideBar from '../../utils/components/sideBar';
import { IoAdd, IoDownload, IoFileTray, IoFileTraySharp, IoSend } from 'react-icons/io5';
import { Modal } from '../../utils/components/modal';
import Card from '../../utils/components/card';
import { similarity } from '../../utils/functions';
import parse from '../../utils/parseOfx';

function ListarRegistros() {
  const [ModalSaldo, setModalSaldo] = useState(false)
  const [ofx, setOFX] = useState(null);
  const [empresas, setEmpresas] = useState([])
  const [page, setPage] = useState(1)
  const [registros, setRegistros] = useState([])
  const [quant_page, setQuant_page] = useState(null)
  const [TotalRegistros, setTotalRegistros] = useState(null)
  const [Conta, setConta] = useState([])
  const [ContaDefault, setContaDefault] = useState([])
  const [Empresa, setEmpresa] = useState([])

  const [loading, setLoading] = useState(false)
  const [loadingEmpresa, setLoadingEmpresa] = useState(false)
  const [loadingConta, setLoadingConta] = useState(false)
  const [loadingSubConta, setLoadingSubConta] = useState(false)

  const [Empresa_id, setEmpresa_id] = useState(null)
  const [Empresa_nome, setEmpresa_nome] = useState(null)
  const [EmpresaDefault, setEmpresaDefault] = useState([])
  const [SubConta, setSubConta] = useState([])
  const [SubContaDefault, setSubContaDefault] = useState([])
  const [de, setDe] = useState('')
  const [ate, setAte] = useState('')
  const [saldo, setSaldo] = useState('')
  const tableRef = useRef(null);
  const navigation = useNavigate()
  const [ModalOFX, setModalOFX] = useState(false)

  const itemsConta = useRef([]);
  const itemsSubConta = useRef([]);

  const [Grupo_id, setGrupo_id] = useState(null)
  const [dados, setDados] = useState([])
  useEffect(() => {
    if (!get('fluxo_Grupo_id'))
      navigation('/login')
    else {
      setGrupo_id(get('fluxo_Grupo_id').Grupo_id);
      if (!ModalOFX) {
        listarRegistros()
        listarValoresPorEmpresa()
        listarEmpresas()
        listarContas()
        listarSubContas()
      }
    }
    // eslint-disable-next-line
  }, [ModalOFX])

  async function cadastrarRegistros() {
    setLoading(true)
    const data = await ofx.filter(el => el.Lancar)
      // .map(el => ({ ...el, Data: moment(el.Data).format('YYYY-MM-DD') }))
    await api.post('/cadastrarRegistros', {
      ofx: data
    }, { headers: { authorization: Grupo_id } })
      .then(result => {
        toastSuccess('Cadastrado com sucesso')
        setModalOFX(false)
        setEmpresa_id(null)
        setOFX([])
      })
      .catch(err => {
        toastError('Erro ao cadastrar')
        console.log(err)
      })
    setLoading(false)
  }
  async function listarEmpresas() {
    let Grupo_id = get('fluxo_Grupo_id').Grupo_id
    await api.get('/listarEmpresas', { headers: { authorization: Grupo_id } })
      .then(result => {
        let array = result.data.map(el => {
          return {
            value: el.Empresa_id,
            Nome: el.Empresa_nome,
            Elemento: el
          }
        })
        setEmpresa(array)
        setEmpresaDefault(array)
      })
      .catch(err => {
        toastError('Erro ao pegar dados')
        console.log(err)
      })
  }
  async function listarContas() {
    let Grupo_id = get('fluxo_Grupo_id').Grupo_id
    await api.get('/listarContas', { headers: { authorization: Grupo_id } })
      .then(result => {
        let array = result.data.map(el => {
          return {
            value: el.Conta_id,
            Nome: el.Conta_nome,
            Elemento: el
          }
        })
        setConta(array)
        setContaDefault(array)
        // filterConta(array)
      })
      .catch(err => {
        toastError('Erro ao pegar dados')
        console.log(err)
      })
  }
  async function listarSubContas() {
    let Grupo_id = get('fluxo_Grupo_id').Grupo_id
    await api.get('/listarSubContas', { headers: { authorization: Grupo_id } })
      .then(result => {
        let array = result.data.map(el => {
          return {
            value: el.SubConta_id,
            Nome: el.SubConta_nome,
            Elemento: el
          }
        })
        setSubContaDefault(array)
      })
      .catch(err => {
        toastError('Erro ao pegar dados')
        console.log(err)
      })
  }
  async function listarAllRegistros() {
    let Grupo_id = get('fluxo_Grupo_id').Grupo_id
    const data = await api.get('/listarAllRegistros?Empresa_id=' + Empresa_id, { headers: { authorization: Grupo_id } })
      .then(result => {
        return result.data
      })
      .catch(err => {
        toastError('Erro ao listar Registros')
        return null
      })
    return data;
  }
  async function filterConta(conta = ContaDefault, tipo) {
    tipo = tipo ? 'Entrada' : 'Saída'
    setConta(conta.filter(el => el.Elemento.Tipo === tipo))
  }

  async function pesquisarEmpresa(text) {
    let array = EmpresaDefault.filter(el => el.Elemento.Empresa_nome.toLowerCase().search(text.toLowerCase()) >= 0)
    if (text.trim().length <= 0) {
      setEmpresa_id(null)
      setEmpresa_nome(null)
    } else {
      setEmpresa(array)
    }
    setLoadingEmpresa(false)
  }
  async function pesquisarConta(text, tipo, index) {
    tipo = tipo ? 'Entrada' : 'Saída'
    let array = ContaDefault.filter(el => el.Elemento.Tipo === tipo && el.Elemento.Conta_nome.toLowerCase().search(text.toLowerCase()) >= 0)
    if (text.trim().length <= 0) {
      setOFX(ofx.map((item, i) => i === index ? { ...item, Conta_id: null, Conta_nome: null, SubConta_id: null } : item))

      filterConta(ContaDefault, tipo)
    } else {
      setConta(array)
    }
    setLoadingConta(false)
  }
  async function filterSubConta(subconta = SubContaDefault, conta_id) {
    setSubConta(subconta.filter(el => el.Elemento.Conta_id === conta_id))
  }
  async function pesquisarSubConta(text, conta_id) {
    let array = SubContaDefault.filter(el => el.Elemento.Conta_id === conta_id && el.Elemento.SubConta_nome.toLowerCase().search(text.toLowerCase()) >= 0)
    if (text.trim().length <= 0) {
      filterSubConta(SubContaDefault)
    } else {
      setSubConta(array)
    }
    setLoadingSubConta(false)
  }
  const handleChange = async (file) => {
    const registros = await listarAllRegistros()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)
      parse(text, (data, err) => {
        if (err)
          console.log(err)
        const temp = data.body.OFX.BANKMSGSRSV1.STMTTRNRS.STMTRS.BANKTRANLIST.STMTTRN.map(el => {
          let IA = registros
            .map(reg => { return { ...reg, similar: similarity(el.MEMO, reg.Observacao) } })
            .filter(reg => ((parseFloat(el.TRNAMT.replace(',', '.')) >= 0 && reg.Tipo === 'Entrada') || (parseFloat(el.TRNAMT.replace(',', '.')) < 0 && reg.Tipo === 'Saída')) && reg.similar > 0.5)// 50% DE SIMILIARIDADE
            .sort((a, b) => b.similar - a.similar)[0]
          return {
            Empresa_id: Empresa_id,
            Data: moment(el.DTPOSTED, 'YYYYMMDD').format('YYYY-MM-DD'),
            Tipo: parseFloat(el.TRNAMT.replace(',', '.')) > 0 ? 'Entrada' : 'Saída',
            Conta_id: IA ? IA.Conta_id : null,
            Conta_nome: IA ? IA.Conta_nome : null,
            SubConta_id: IA ? IA.SubConta_id : null,
            SubConta_nome: IA ? IA.SubConta_nome : null,
            Observacao: el.MEMO,
            Valor: parseFloat(el.TRNAMT.replace(',', '.')),
            Lancar: false
          }
        })
        itemsConta.current = itemsConta.current.slice(0, temp.length);
        itemsSubConta.current = itemsSubConta.current.slice(0, temp.length);
        setOFX(temp)
        setModalOFX(true)

      })
    };
    reader.readAsText(file)


    //     const formData = new FormData();
    //     formData.append("file", file);
    //     formData.append("fileName", 'fileOfx');
    //     formData.append("name", 'fileOfx');
    // console.log(file)
    // console.log(formData)
    //     const registros = await listarAllRegistros()

    //     await api.post('/uploadOFX',
    //       formData
    //       , { headers: { "Content-Type": "multipart/form-data",authorization: Grupo_id } })
    //       .then(result => {
    //         const temp = result.data.body.OFX.BANKMSGSRSV1.STMTTRNRS.STMTRS.BANKTRANLIST.STMTTRN.map(el => {
    //           let IA = registros
    //             .map(reg => { return { ...reg, similar: similarity(el.MEMO, reg.Observacao) } })
    //             .filter(reg => ((parseFloat(el.TRNAMT.replace(',', '.')) >= 0 && reg.Tipo === 'Entrada') || (parseFloat(el.TRNAMT.replace(',', '.')) < 0 && reg.Tipo === 'Saída')) && reg.similar > 0.5)// 50% DE SIMILIARIDADE
    //             .sort((a, b) => b.similar - a.similar)[0]
    //           return {
    //             Empresa_id: Empresa_id,
    //             Data: moment(el.DTPOSTED, 'YYYYMMDD').format('DD/MM/YYYY'),
    //             Tipo: parseFloat(el.TRNAMT.replace(',', '.')) > 0 ? 'Entrada' : 'Saída',
    //             Conta_id: IA ? IA.Conta_id : null,
    //             Conta_nome: IA ? IA.Conta_nome : null,
    //             SubConta_id: IA ? IA.SubConta_id : null,
    //             SubConta_nome: IA ? IA.SubConta_nome : null,
    //             Observacao: el.MEMO,
    //             Valor: parseFloat(el.TRNAMT.replace(',', '.')),
    //             Lancar: false
    //           }
    //         })
    //         itemsConta.current = itemsConta.current.slice(0, temp.length);
    //         itemsSubConta.current = itemsSubConta.current.slice(0, temp.length);
    //         setOFX(temp)
    //         setModalOFX(true)
    //       })
  };
  async function listarRegistros(pagina = page) {
    let Grupo_id = get('fluxo_Grupo_id').Grupo_id
    await api.get('/listarRegistros?page=' + pagina, { headers: { authorization: Grupo_id } })
      .then(result => {
        setTotalRegistros(parseInt(result.headers.total_registros))
        setSaldo(parseInt(result.headers.saldo))
        setQuant_page(50)
        setRegistros(result.data)
        setPage(pagina)
      })
      .catch(err => {
        toastError('Erro ao listar Registros')
        console.log(err)
      })
  }
  async function listarValoresPorEmpresa(pagina = page) {
    let Grupo_id = get('fluxo_Grupo_id').Grupo_id
    await api.get('/listarValoresPorEmpresa', { headers: { authorization: Grupo_id } })
      .then(result => {
        setEmpresas(result.data)
      })
      .catch(err => {
        toastError('Erro ao listar Registros')
        console.log(err)
      })
  }
  async function indicadores() {
    let Grupo_id = get('fluxo_Grupo_id').Grupo_id

    await api.post('/indicadores', {
      de, ate
    }, { headers: { authorization: Grupo_id } })
      .then(result => {
        setDados(result.data)
        setTimeout(() => {
          onDownload()
        }, 1500)
      })
      .catch(err => {
        toastError('Erro ao listar Registros')
        console.log(err)
      })
  }
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Registros de ' + moment(de).format('DD/MM/YYYY') + " até " + moment(ate).format('DD/MM/YYYY'),
    sheet: 'Registros'
  })
  return (
    <div style={{ maxHeigth: '50vh' }}>


      <TableList
        title={
          <div style={{ display: "flex", alignItems: 'center' }}>
            <SideBar>
              <h3 style={{ padding: 10 }}>Selecione o período:</h3>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <InputText
                  percento_width={58}
                  span={'De:'}
                  value={de}
                  onChange={(text) => setDe(text)}
                  type={'date'}
                />
                <InputText
                  percento_width={58}
                  span={'Até:'}
                  value={ate}
                  onChange={(text) => setAte(text)}
                  type={'date'}
                />

                <ButtonOutline style={{ marginTop: 5 }}
                  percento_width={100}
                  disabled={!de || !ate}
                  onClick={() => indicadores()}
                  color={'var(--green)'}
                  icon={<SiMicrosoftexcel />}> Exportar para Excel </ButtonOutline>


              </div>
            </SideBar>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <div>
                <h3>Lista de Registros </h3>
                <div style={{ display: 'flex', alignItens: "center" }}>
                  {
                    saldo > 0 ?
                      <p>Saldo em Conta:  <b style={{ color: 'var(--green)' }}>R$ {numberToCurrency(saldo)}</b></p>
                      :
                      <p>Débito em Conta:  <b style={{ color: 'var(--red)' }}>R$ {numberToCurrency(saldo)}</b></p>
                  }
                  <p className='vermaisText' onClick={() => setModalSaldo(true)}>Ver Mais</p>
                </div>
              </div>
              <ButtonOutline
                onClick={() => setModalOFX(true)}
                icon={<IoDownload />}>
                Importar OFX
              </ButtonOutline>
            </div>
          </div>}
        stickyTop={-21}
        quant_page={quant_page}
        quant_dados={TotalRegistros}
        page={page}
        onSelectPage={(pagina) => { listarRegistros(pagina) }}
        onSelected={(id) => {
          console.log(id)
        }}
        // search_span={'Pesquisar Cliente'}
        columns={['Ação', 'Data', 'Tipo', 'Conta', 'Sub Conta', 'Valor', 'Empresa', 'Observação']}
        // link={'/menuCliente/'}
        dados={registros.map(el => {
          return [
            el.Registro_id,
            <Link to={"/editarRegistro/" + el.Registro_id}>
              <AiFillEdit />
            </Link>,
            moment(el.Data).format('DD/MM/YYYY'),
            el.Tipo,
            el.Conta_nome,
            el.SubConta_nome,
            <p style={{ color: el.Tipo === 'Entrada' ? 'var(--green)' : 'var(--red)' }}>{numberToCurrency(el.Tipo === 'Entrada' ? el.Valor : el.Valor * -1)}</p>,
            el.Empresa_nome,
            el.Observacao.length > 20 ? el.Observacao.substring(0, 20) + '...' : el.Observacao,
          ]
        })}

      />

      <table ref={tableRef} style={{ display: 'none' }}>
        <tbody>
          <tr>
            <th>Tipo</th>
            <th>Mês</th>
            <th>Data</th>
            <th>Conta</th>
            <th>Sub Conta</th>
            <th>Valor</th>
            <th>Empresa</th>
            <th>Observacao</th>
          </tr>
          {
            dados.map((el, index) => (
              <tr key={index}>
                <td>{el.Tipo}</td>
                <td>{moment(el.Data).format('MMM')}</td>
                <td>{el.Data}</td>
                <td>{el.Conta_nome}</td>
                <td>{el.SubConta_nome}</td>
                <td style={{ color: el.Tipo === 'Saída' ? 'red' : 'blue' }}>{el.Tipo === 'Entrada' ? el.Valor.replace('.', ',') : (parseFloat(el.Valor) * -1).toString().replace('.', ',')}</td>
                <td>{el.Empresa_nome}</td>
                <td>{el.Observacao}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <Modal htmlFor="modalSaldosContas" showModal={[ModalSaldo, setModalSaldo]}>
        <Card percento_width={80} style={{ padding: 5 }}>
          <h2>Saldos/ Débitos por Contas</h2>
          <table className='tableGlobal'>
            <thead>
              <tr>
                <th>Empresa</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {
                empresas.map(el => (
                  <tr key={el.Empresa_id}>
                    <td>{el.Empresa_nome}</td>
                    <td style={{ color: el.Valor > 0 ? 'var(--green)' : 'var(--red)' }}>R$ {numberToCurrency(el.Valor)}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </Card>
      </Modal>
      <Modal htmlFor="modalOFX" showModal={[ModalOFX, setModalOFX]} >
        <Card percento_width={80} style={{ minHeight: '80%' }}>
          <div style={{ justifyContent: 'space-around', alignItems: "center", display: 'flex', margin: 10 }}>

            <SelectCreate
              percento_width={30}
              required
              disabled={loading}
              ignoreLength
              defaultData={Empresa}
              data={Empresa}
              onlyNome
              value={Empresa_id}
              updateText={Empresa_id !== null ? Empresa_nome : null}
              onClear={() => {
                setEmpresa_id(null)
                setEmpresa_nome(null)
              }}
              onSelectOption={(el) => {
                setEmpresa([])
                setEmpresa_id(el.Empresa_id)
                setEmpresa_nome(el.Empresa_nome)
              }}
              span={'Selecione uma empresa'}
              onChange={(texto) => pesquisarEmpresa(texto)}
              setLoading={setLoadingEmpresa}
              loading={loadingEmpresa}
            />
            <FileUploader
              label="Clique ou Arraste o arquivo aqui"
              disabled={!Empresa_id}
              multiple={false}
              handleChange={handleChange}
              name="fileOfx"
              types={['OFX']} />
          </div>

          <table className='tableGlobal'>
            <thead>
              <tr>
                <th></th>
                <th>Data</th>
                <th>Tipo</th>
                <th>Conta</th>
                <th>SubConta</th>
                <th>Observacao</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {
                ofx && ofx.map((el, index) => (
                  <tr key={index} className={ofx[index].Lancar ? 'ofxSelected' : 'ofxDontSelected'}>
                    <td ><input type="checkbox" checked={ofx[index].Lancar} onChange={() => ofx[index].Conta_id && ofx[index].SubConta_nome && setOFX(ofx.map((item, i) => i === index ? { ...item, Lancar: !item.Lancar } : item))} /></td>
                    <td onClick={() => ofx[index].Conta_id && ofx[index].SubConta_nome && setOFX(ofx.map((item, i) => i === index ? { ...item, Lancar: !item.Lancar } : item))}>{el.Data}</td>
                    <td onClick={() => ofx[index].Conta_id && ofx[index].SubConta_nome && setOFX(ofx.map((item, i) => i === index ? { ...item, Lancar: !item.Lancar } : item))}>{el.Tipo}</td>
                    <td> <SelectCreate
                      ref_={el => itemsConta.current[index] = el}

                      dontCreate
                      disabled={loading}
                      ignoreLength
                      defaultData={Conta}
                      data={Conta}
                      onlyNome
                      value={ofx[index].Conta_id}
                      updateText={ofx[index].Conta_id !== null ? ofx[index].Conta_nome : null}
                      onClear={() => {
                        let temp = ofx.map((reg, i) => i !== index ? reg : { ...reg, Conta_id: null, Conta_nome: null, SubConta_id: null, Lancar: false })
                        setOFX(temp)
                        // setConta_id(null)
                        // setConta_nome(null)
                        filterConta(ContaDefault, ofx[index].Valor >= 0 ? true : false)
                        // setSubConta_id(null)
                        setSubConta([])
                        itemsConta.current[index].focus()
                      }}
                      onSelectOption={(el) => {
                        // setConta([])
                        let temp = ofx.map((reg, i) => i !== index ? reg : { ...reg, Conta_id: el.Conta_id, Conta_nome: el.Conta_nome, SubConta_id: null })
                        setOFX(temp)
                        // setConta_id(el.Conta_id)
                        // setConta_nome(el.Conta_nome)
                        // filterSubConta(SubContaDefault, el.Conta_id)
                        // setSubConta_id(null)
                        setTimeout(() => itemsSubConta.current[index].focus(), 200)
                      }}
                      onFocus={() => filterConta(ContaDefault, ofx[index].Valor >= 0 ? true : false)}
                      onChange={(texto) => pesquisarConta(texto, ofx[index].Valor > 0 ? true : false)}
                      setLoading={setLoadingConta}
                      loading={loadingConta}
                    // onCreate={() => {
                    //   setConta_nome(inputConta.current.value)
                    //   setConta_id(0)
                    // }}
                    />
                    </td>
                    <td> <SelectCreate
                      ref_={el => itemsSubConta.current[index] = el}
                      ignoreLength
                      disabled={ofx[index].Conta_id === null || loading}
                      defaultData={SubConta}
                      data={SubConta}
                      onlyNome
                      value={ofx[index].SubConta_id}
                      updateText={ofx[index].SubConta_id !== null ? ofx[index].SubConta_nome : null}
                      onClear={() => {
                        let temp = ofx.map((reg, i) => i !== index ? reg : { ...reg, SubConta_id: null, SubConta_nome: null, Lancar: false })
                        setOFX(temp)
                        // setSubConta_id(null)
                        // setSubConta_nome(null)
                        filterSubConta()
                        itemsSubConta.current[index].focus()
                      }}
                      onSelectOption={(el) => {
                        let temp = ofx.map((reg, i) => i !== index ? reg : { ...reg, SubConta_id: el.SubConta_id, SubConta_nome: el.SubConta_nome, Lancar: true })
                        setOFX(temp)
                        if (index + 1 < ofx.length)
                          itemsConta.current[index + 1].focus()
                      }}
                      onChange={(texto) => pesquisarSubConta(texto, ofx[index].Conta_id)}
                      onFocus={() => ofx[index].Conta_id !== null && filterSubConta(SubContaDefault, ofx[index].Conta_id)}
                      setLoading={setLoadingSubConta}
                      loading={loadingSubConta}
                      onCreate={() => {
                        let temp = ofx.map((reg, i) => i !== index ? reg : { ...reg, SubConta_id: 0, SubConta_nome: itemsSubConta.current[index].value, Lancar: true })
                        setOFX(temp)
                        // setSubConta_nome(inputSubConta.current.value)
                        // setSubConta_id(0)
                        setTimeout(() => {
                          if (index + 1 < ofx.length)
                            itemsConta.current[index + 1].focus()
                        }, 300)
                      }}
                    /></td>
                    <td onClick={() => ofx[index].Conta_id && ofx[index].SubConta_nome && setOFX(ofx.map((item, i) => i === index ? { ...item, Lancar: !item.Lancar } : item))}>{el.Observacao}</td>
                    <td onClick={() => ofx[index].Conta_id && ofx[index].SubConta_nome && setOFX(ofx.map((item, i) => i === index ? { ...item, Lancar: !item.Lancar } : item))} style={{ color: el.Valor > 0 ? "var(--green)" : 'var(--red)' }}>{el.Valor}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            ofx && ofx.filter(el => el.Lancar).length > 0 &&
            < ButtonLoading style={{ float: 'right', margin: 10 }}
              onClick={() => cadastrarRegistros()}
              icon={<IoSend />}>
              Adicionar {ofx.filter(el => el.Lancar).length} de {ofx.length}
            </ButtonLoading>
          }
        </Card>
      </Modal>
    </div >
  );
}

export default ListarRegistros;