import React from 'react';
import { IoChevronBack, IoCloseOutline } from 'react-icons/io5';
import '../components/styles/modal.css'

export function ShowModal(id) {
  document.getElementById('modal_' + id).click()

}
export function ModalButton(props) {
  return (
    <label htmlFor={`modal_${props.htmlFor}`}>{props.children}</label>
  )
}
export function Modal(props) {
  return (
    <div>
      <input type="checkbox" id={`modal_${props.htmlFor}`} checked={props.showModal[0]}
        onChange={() => { props.showModal[1](false) }}
      />
      <div className="modal">

        {props.showModal[0] && props.children}
        <label className="modal-close" htmlFor={`modal_${props.htmlFor}`}>
          <IoCloseOutline className="iconClose" />
        </label>
      </div>
    </div>
  );
}
export function ModalFullPage(props) {
  return (
    <div>
      {props.showModal[0] &&
        <div className='container-ModalFullpage'>
          <div className='onGoBack-ModalFullPage'
            onClick={() => props.showModal[1](false)}
          >
            <IoChevronBack
              color='var(--text-primary)'
              size={24}
            />
            <p>Voltar</p>
          </div>
          {props.children}
        </div>
      }
    </div>

  )
}
