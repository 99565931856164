/* eslint-disable */
export function checkInputs(query) {
  if (query === null)
    return 0
  const container_inputs_text = document.querySelectorAll(query + ' .InputTextContainer')
  const container_inputs_currency = document.querySelectorAll(query + ' .InputCurrencyContainer')

  const invalid_inputs = Array.from([...container_inputs_currency, ...container_inputs_text]).filter(e => {
    let input = e.querySelector('input, textarea')
    let con = e.querySelector('#InputTextInput')
    let span = e.querySelector('span')

    if (!input.validity.valid) {
      // console.log(input)
      if (e.querySelector('#borderInputSelect'))
        e.querySelector('#borderInputSelect').style.borderColor = 'var(--accent)';
      if (span)
        span.innerHTML = input.validationMessage
      con.style.borderColor = 'var(--accent)';
      span.style.display = 'block';
      return input
    }
  })
  return invalid_inputs
}
export function removeCheckInputs(query) {
  if (query === null)
    return 0
  const container_inputs_text = document.querySelectorAll(query + ' .InputTextContainer')
  const container_inputs_currency = document.querySelectorAll(query + ' .InputCurrencyContainer')

  const invalid_inputs = Array.from([...container_inputs_currency, ...container_inputs_text]).filter(e => {
    let input = e.querySelector('input, textarea')
    let con = e.querySelector('#InputTextInput')
    let span = e.querySelector('span')

    if (e.querySelector('#borderInputSelect'))
      e.querySelector('#borderInputSelect').style.borderColor = 'var(--border-color)';
    if (span)
      span.innerHTML = ''
    con.style.borderColor = 'var(--border-color)';
    return input

  })
  return invalid_inputs
}
function editDistance(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  var costs = new Array();
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i == 0)
        costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue),
              costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0)
      costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}
export function similarity(s1, s2) {
  var longer = s1;
  var shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  var longerLength = longer.length;
  if (longerLength == 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}
export function formatacaoCpfCnpj(value) {
  if (value.length <= 11) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")
  } else {
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")
  }
}
export function monthsBetween(...args) {
  let [a, b] = args.map(arg => arg.split("-").slice(0, 2)
    .reduce((y, m) => m - 1 + y * 12));
  return Array.from({ length: b - a + 1 }, _ => a++)
    .map(m => ~~(m / 12) + "-" + ("0" + (m % 12 + 1)).slice(-2) + "-01");
}
export function groupBy(arr, key) {
  const initialValue = {};
  return arr.reduce((acc, cval) => {
    const myAttribute = cval[key];
    acc[myAttribute] = [...(acc[myAttribute] || []), cval]
    return acc;
  }, initialValue);
};
export function numberToCurrency(amount) {
  let decimalCount = 2
  decimalCount = Math.abs(decimalCount)
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount

  const negativeSign = amount < 0 ? "-" : ""

  const i = parseInt(amount = Math.abs(Number(amount) ||
    0).toFixed(decimalCount)).toString()
  const j = (i.length > 3) ? i.length % 3 : 0

  return negativeSign + (j ? i.substr(0, j) + '.' : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + '.') + (decimalCount ?
      ',' + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "")
};

export function capitalize(text) {
  return text.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}