import React, { useState } from 'react';
import { IoClose, IoExit, IoMenu, IoSwapHorizontal } from 'react-icons/io5';

import './styles/sidebar.css';

function SideBar(props) {
  const [open,setOpen]=useState(false)
  return (
    <div className='sideBarContainer'>
      <IoMenu style={{paddingTop:5}} size={26} color={'#363636'} onClick={()=>setOpen(true)}/>
      <div className='blackScreen' style={{display:open?'flex':'none'}} onClick={()=>setOpen(false)}></div>
      <div className='lateralBarContainer' style={{left:open?0:-500}}>
        <IoClose style={{paddingTop:5}} size={26} color={'#363636'}  onClick={()=>setOpen(false)}/>
        {props.children}
      </div>
    </div>
  );
}

export default SideBar;